import {
  Box,
  Container,
  Link,
  List,
  ListItemText,
  Typography,
  ButtonBase,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ArrowUp from "./icons/ArrowUp";
import FacebookCircle from "./icons/FacebookCircle";
import InstagramCircle from "./icons/InstagramCircle";
import NineEightEightSuicideCrisisLifelineLogoEN from "./icons/NineEightEightSuicideCrisisLifelineLogoEN";
import NineEightEightSuicideCrisisLifelineLogoES from "./icons/NineEightEightSuicideCrisisLifelineLogoES";
import XCircle from "./icons/XCircle";
import YouTubeCircle from "./icons/YouTubeCircle";
import { replaceMainSiteLinkHostname } from "../services/Utils";
import theme from "../themes/theme";

export default function Footer() {
  return (
    <Box as="footer">
      <ReturnToTop />
      <FooterContent />
    </Box>
  );
}

function ReturnToTop() {
  const { t } = useTranslation();

  return (
    <ButtonBase
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        py: 3,
        backgroundColor: theme.palette.primary.main,
        ":hover": { backgroundColor: "primary.contrast" },
        transition: theme.transitions.create(["all"], {
          duration: ".3s",
          easing: "ease-out",
        }),
      })}
    >
      <Container
        maxWidth="lg"
        sx={{ display: "flex", alignItems: "center", gap: "2rem" }}
      >
        <Typography sx={{ color: "white", fontSize: 16, fontWeight: 700 }}>
          {t("footer.returnToTop")} <ArrowUp sx={{ fontSize: 16 }} />
        </Typography>
      </Container>
    </ButtonBase>
  );
}

function FooterContent() {
  return (
    <Box
      sx={{
        py: 4.6,
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          justifyContent: "space-between",
          margin: "0 auto",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "unset",
            alignItems: "flex-start",
          },
          [theme.breakpoints.up("medium")]: {
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            gap: "4rem",
          },
        })}
      >
        <SiteInfo />
        <Box
          sx={(theme) => ({
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
            flexDirection: "column",
            [theme.breakpoints.up("tiny")]: {
              flexDirection: "unset",
            },
            [theme.breakpoints.up("medium")]: {
              flexWrap: "nowrap",
            },
          })}
        >
          <FooterLinks />
          <SocialMediaLinks />
        </Box>
      </Container>
    </Box>
  );
}

function SiteInfo() {
  return (
    <Box
      sx={(theme) => ({
        borderBottom: "1px solid hsla(0, 0%, 100%, 0.3)",
        [theme.breakpoints.up("small")]: {
          borderBottom: "none",
        },
        [theme.breakpoints.up("medium")]: {
          flexBasis: "26rem",
        },
        marginBottom: 1,
        paddingBottom: 1,
      })}
    >
      <Box
        as="nav"
        sx={(theme) => ({
          maxWidth: 380,
          [theme.breakpoints.up("sm")]: {
            flexBasis: "42rem",
            margin: 0,
            padding: 0,
          },
        })}
      >
        <LogoBlock />
      </Box>
      <Tagline />
      <Credits />
    </Box>
  );
}

function LogoBlock() {
  const { t, i18n } = useTranslation();

  return (
    <Link
      href={replaceMainSiteLinkHostname(t("navigationMenu.home.url"))}
      target="_blank"
      rel="noopener"
      sx={(theme) => ({
        display: "block",
        color: "#fff",
        ":hover": {
          color: theme.palette.primary.light,
        },
        ":focus": {
          color: theme.palette.primary.light,
          outline: `1px dashed ${theme.palette.pink300}`,
          outlineOffset: 4,
        },
        transition: theme.transitions.create(["color", "transform"]),
      })}
    >
      {i18n?.language === "es" ? (
        <NineEightEightSuicideCrisisLifelineLogoES
          fill="inherit"
          sx={{ width: "100%", height: "auto" }}
        />
      ) : (
        <NineEightEightSuicideCrisisLifelineLogoEN
          fill="inherit"
          sx={{ width: "100%", height: "auto" }}
        />
      )}
    </Link>
  );
}

function Tagline() {
  const { t } = useTranslation();

  return (
    <Typography
      sx={(theme) => ({
        my: "9.3px",
        color: "white",
        fontSize: 18,
        [theme.breakpoints.up("medium")]: {
          fontSize: 19,
        },
        lineHeight: "180%",
      })}
    >
      {t("footer.tagline")}
    </Typography>
  );
}

function Credits() {
  return (
    <List sx={{ p: 0 }}>
      <FooterLink href="https://www.samhsa.gov/" display="block">
        SAMHSA.gov
      </FooterLink>
      <FooterLink href="https://www.vibrant.org/" display="block">
        Vibrant.org
      </FooterLink>
    </List>
  );
}

function FooterLinks() {
  const { i18n, t } = useTranslation();
  const links =
    i18n?.language === "es"
      ? [
          "contact",
          "hearing",
          "donate",
          "faq",
          "mediaResources",
          "belongingAccessibility",
          "confidentiality",
          "privacy",
          "terms",
          "vulnerabilityDisclosure",
        ]
      : [
          "contact",
          "hearing",
          "donate",
          "faq",
          "mediaResources",
          "belongingAccessibility",
          "confidentiality",
          "privacy",
          "terms",
          "vulnerabilityDisclosure",
        ];

  return (
    <List
      sx={(theme) => ({
        flexShrink: 6,
        p: 0,
        columnGap: "2rem",
        [theme.breakpoints.up("tiny")]: {
          columns: 2,
        },
        [theme.breakpoints.up("small")]: {
          columns: 1,
        },
        [theme.breakpoints.up("medium")]: {
          columns: 2,
        },
      })}
      aria-label={t("footer.menuLinksLabel")}
    >
      {links.map((link) => (
        <FooterLink
          key={link}
          href={replaceMainSiteLinkHostname(t(`footer.links.${link}.url`))}
        >
          {t(`footer.links.${link}.text`)}
        </FooterLink>
      ))}
    </List>
  );
}

function SocialMediaLinks() {
  const { t } = useTranslation();

  return (
    <List sx={{ m: 0, p: 0 }} aria-label={t("footer.socialMediaLinksLabel")}>
      <SocialMediaLink
        label="Instagram"
        icon={<InstagramCircle />}
        url="https://www.instagram.com/988lifeline/"
      />
      <SocialMediaLink
        label="Facebook"
        url="https://www.facebook.com/988lifeline/"
        icon={<FacebookCircle />}
      />
      <SocialMediaLink
        label="YouTube"
        url="https://www.youtube.com/988Lifeline"
        icon={<YouTubeCircle />}
      />
      <SocialMediaLink
        label="X"
        url="https://twitter.com/988Lifeline"
        icon={<XCircle />}
      />
    </List>
  );
}

function FooterLink({ children, ...props }) {
  return (
    <ListItemText sx={{ mb: 1.5 }}>
      <Link
        target="_blank"
        rel="noopener"
        sx={(theme) => ({
          margin: 0,
          padding: 0,
          fontSize: 15,
          textDecoration: "underline",
          color: theme.palette.primary.light,
          ":hover": {
            color: theme.palette.pink300,
          },
          ":focus": {
            color: theme.palette.pink300,
            outline: `1px dashed ${theme.palette.pink300}`,
            outlineOffset: 4,
          },
          transition: theme.transitions.create(["color", "transform"]),
        })}
        {...props}
      >
        {children}
      </Link>
    </ListItemText>
  );
}

FooterLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function SocialMediaLink({ icon, label, url }) {
  return (
    <FooterLink
      href={url}
      sx={(theme) => ({
        margin: 0,
        padding: 0,
        display: "flex",
        alignItems: "center",
        gap: 1,
        textDecoration: "none",
        color: "white",
        ":hover": { color: theme.palette.pink300 },
        ":focus": {
          color: theme.palette.pink300,
          outline: `1px dashed ${theme.palette.pink300}`,
          outlineOffset: 4,
        },
        transition: theme.transitions.create(["color", "transform"]),
      })}
    >
      {icon}
      <Box sx={visuallyHidden}>See 988 Lifeline on</Box>
      {label}
    </FooterLink>
  );
}

SocialMediaLink.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
