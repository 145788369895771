import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "./icons/CloseIcon";
import PropTypes from "prop-types";
import SurveyQuestion from "./SurveyQuestion";
import SurveyPillQuestion from "./SurveyPillQuestion";
import SurveyTextField from "./SurveyTextField";
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import { getChoices } from "../choices";

const SurveyAccordion = ({ errors, control, spacingTop }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    const scrollPosition = window.scrollY;
    setExpanded(isExpanded);

    // Prevent the page from jumping up or down when the accordion changes
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollPosition);
    });
  };

  const RotatableAddIcon = styled(CloseIcon)(({ rotate }) => ({
    transition: "transform 0.3s",
    transform: rotate ? "rotate(45deg)" : "rotate(0deg)",
  }));

  const { t } = useTranslation();
  const { genderIdentityChoices, transgenderChoices, mainConcernChoices } =
    getChoices(t);

  return (
    // Use unmountOnExit to unmount hidden Accordion content for improved performance
    <Box sx={{ marginTop: spacingTop }}>
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          "&:before": { display: "none" },
        }}
        slotProps={{ transition: { unmountOnExit: true, timeout: 0 } }}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={
            <RotatableAddIcon
              rotate={expanded ? 0 : 1}
              sx={{ fontSize: "20px", color: "white" }}
            />
          }
          sx={{
            "&.Mui-expanded": {
              margin: 0,
            },
            ".MuiAccordionSummary-content": {
              margin: 0,
              flexDirection: "column",
            },
            bgcolor: theme.palette.primary.main,
            color: "white",
            borderRadius: "4px",
            padding: "10px 16px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "Public Sans",
              fontSize: "16px",
              fontWeight: "800",
            }}
          >
            {expanded
              ? t("optionalQuestionHeader.collapseTitle")
              : t("optionalQuestionHeader.expandTitle")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {expanded && <SubText />}

          <SurveyTextField
            label={t("nameAliasQuestion.placeHolder")}
            name="name"
            autoComplete="name"
            title={t("nameAliasQuestion.title")}
            description={t("nameAliasQuestion.infoText")}
            control={control}
            inputProps={{ maxLength: 50 }}
          />

          <SurveyTextField
            label={t("ageQuestion.placeHolder")}
            name="age"
            title={t("ageQuestion.title")}
            description={t("ageQuestion.infoText")}
            control={control}
            maskFormat="###"
            inputProps={{ inputMode: "numeric" }}
          />
          <SurveyQuestion
            question={t("genderIdentityQuestion.title")}
            options={genderIdentityChoices}
            name="gender"
            control={control}
            spacingTop={3}
            spacingBottom={1.5}
            errors={errors}
          />
          <SurveyQuestion
            question={t("transgenderQuestion.title")}
            options={transgenderChoices}
            name="transgender"
            control={control}
            spacingTop={1.5}
            spacingBottom={3}
            errors={errors}
          />
          <SurveyPillQuestion
            mainLabelText={t("mainConcernsQuestion.title")}
            subLabelText={t("mainConcernsQuestion.subText")}
            control={control}
            spacingTop={1.5}
            spacingBottom={3}
            options={mainConcernChoices}
            name="mainConcerns"
          />
        </AccordionDetails>
      </Accordion>
      {!expanded && <SubText />}
    </Box>
  );
};

function SubText() {
  const { t } = useTranslation();

  return (
    <Typography
      variant="body1"
      sx={{
        fontStyle: "italic",
        color: theme.palette.secondary.dark,
        mt: 1,
        mb: 3,
      }}
    >
      {t("optionalQuestionHeader.subText")}
    </Typography>
  );
}

SurveyAccordion.propTypes = {
  errors: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  spacingTop: PropTypes.number,
};

export default SurveyAccordion;
