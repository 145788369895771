import { SvgIcon } from "@mui/material";
import theme from "../../themes/theme";

const DropdownIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      style={{
        transform: "scale(1.7)",
      }}
      viewBox="0 5 40 30"
    >
      <path
        d="M38.457 18.3164L33.2031 23.5703C33.112 23.6745 33.0013 23.7526 32.8711 
        23.8047C32.7539 23.8438 32.6302 23.8633 32.5 23.8633C32.3698 23.8633 32.2396 
        23.8372 32.1094 23.7852C31.9922 23.7331 31.888 23.6615 31.7969 23.5703L26.543 
        18.3164C26.3477 18.1211 26.25 17.8867 26.25 17.6133C26.25 17.3398 26.3477 17.1055 
        26.543 16.9102C26.7383 16.7148 26.9727 16.6172 27.2461 16.6172C27.5195 16.6172 
        27.7474 16.7148 27.9297 16.9102L32.5195 21.4805L37.0898 16.9102C37.2852 16.7148 
        37.5195 16.6172 37.793 16.6172C38.0664 16.6172 38.3008 16.7148 38.4961 16.9102C38.6784 17.1055 
        38.7695 17.3398 38.7695 17.6133C38.7695 17.8867 38.6784 18.1211 38.4961 18.3164H38.457Z"
        fill={theme.palette.secondary.main}
      />
    </SvgIcon>
  );
};

export default DropdownIcon;
