import { SvgIcon } from "@mui/material";

export default function ArrowUp(props) {
  return (
    <SvgIcon
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 5.07812C10 5.3125 9.91536 5.51432 9.74609 5.68359C9.58984 5.83984 9.39453 5.91797 9.16016 5.91797C9.04297 5.91797 8.93229 5.89844 8.82812 5.85938C8.73698 5.80729 8.65234 5.74219 8.57422 5.66406L5.83984 2.92969V12.0898C5.83984 12.3242 5.75521 12.526 5.58594 12.6953C5.42969 12.8516 5.23438 12.9297 5 12.9297C4.76562 12.9297 4.5638 12.8516 4.39453 12.6953C4.23828 12.526 4.16016 12.3242 4.16016 12.0898V2.92969L1.42578 5.66406C1.34766 5.74219 1.25651 5.80729 1.15234 5.85938C1.0612 5.89844 0.957031 5.91797 0.839844 5.91797C0.722656 5.91797 0.611979 5.89844 0.507812 5.85938C0.416667 5.80729 0.332031 5.74219 0.253906 5.66406C0.175781 5.58594 0.117188 5.49479 0.078125 5.39062C0.0390625 5.28646 0.0195312 5.18229 0.0195312 5.07812C0.0195312 4.96094 0.0390625 4.85677 0.078125 4.76562C0.117188 4.66146 0.175781 4.57031 0.253906 4.49219L3.82812 0.917969C3.98438 0.761719 4.16016 0.644531 4.35547 0.566406C4.5638 0.47526 4.78516 0.429688 5.01953 0.429688C5.24089 0.429688 5.44922 0.47526 5.64453 0.566406C5.85286 0.644531 6.03516 0.761719 6.19141 0.917969L9.76562 4.49219C9.84375 4.57031 9.90234 4.66146 9.94141 4.76562C9.99349 4.85677 10.0195 4.96094 10.0195 5.07812H10Z"
        fill="white"
      />
    </SvgIcon>
  );
}
