import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

const ASLIcon = ({ fill = "inherit", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="20 5 30 30">
      <path
        d="M31.2285 26.0312C31.1504 25.8099 31.0137 25.6667 30.8184 25.6016C30.623 25.5234 30.3822 
        25.5299 30.0957 25.6211C29.627 25.7773 29.1647 25.9401 28.709 26.1094C28.2533 26.2656 27.7975
        26.4219 27.3418 26.5781C26.8861 26.7344 26.4303 26.8971 25.9746 27.0664C25.5189 27.2227 25.0371
        27.3854 24.5293 27.5547V27.0078C24.5293 26.3177 24.5293 25.6276 24.5293 24.9375C24.5423 24.2474 
        24.5423 23.5573 24.5293 22.8672C24.5293 22.1771 24.5293 21.4935 24.5293 20.8164C24.5163 20.1263 
        24.5033 19.4362 24.4902 18.7461C24.4772 18.3424 24.5293 18.0169 24.6465 17.7695C24.7767 17.5221 
        25.0436 17.3464 25.4473 17.2422C25.7077 17.1901 25.8835 17.0729 25.9746 16.8906C26.0788 16.6953 
        26.1178 16.4674 26.0918 16.207C26.0788 15.8945 26.0723 15.582 26.0723 15.2695C26.0853 14.957 26.0918
        14.625 26.0918 14.2734C26.3652 14.4948 26.6191 14.7031 26.8535 14.8984C27.1009 15.0938 27.3353 15.2956 
        27.5566 15.5039C27.778 15.7122 27.9993 15.8359 28.2207 15.875C28.4421 15.901 28.6829 15.849 28.9434 15.7188C29.2819
        15.5495 29.6204 15.3867 29.959 15.2305C30.2975 15.0742 30.6426 14.9115 30.9941 14.7422C31.5671 14.4688 32.1465 
        14.1953 32.7324 13.9219C33.3184 13.6484 33.9108 13.3685 34.5098 13.082V15.3672C34.4577 15.3672 34.4056 15.3672 
        34.3535 15.3672C34.3145 15.3542 34.2754 15.3542 34.2363 15.3672C34.1322 15.4062 34.015 15.4388 33.8848 15.4648C33.7546 
        15.4779 33.6764 15.5169 33.6504 15.582C33.6243 15.6732 33.6243 15.7839 33.6504 15.9141C33.6895 16.0312 33.7415 16.1419 
        33.8066 16.2461C33.9889 16.5456 34.1777 16.8385 34.373 17.125C34.5684 17.4115 34.7702 17.6979 34.9785 17.9844C35.1087 
        18.1927 35.2389 18.2969 35.3691 18.2969C35.5124 18.2969 35.6491 18.1927 35.7793 17.9844C35.9486 17.724 36.1113 17.4635 
        36.2676 17.2031C36.4368 16.9427 36.6061 16.6823 36.7754 16.4219C37.0488 15.9922 37.153 15.7188 37.0879 15.6016C37.0228 
        15.4714 36.7363 15.3932 36.2285 15.3672C36.2155 15.3672 36.196 15.3607 36.1699 15.3477C36.1569 15.3346 36.1309 15.3216 
        36.0918 15.3086V12.3008C36.0918 11.7279 35.9746 11.3698 35.7402 11.2266C35.5059 11.0703 35.1348 11.1094 34.627 11.3438C33.6895
        11.7865 32.7454 12.2292 31.7949 12.6719C30.8574 13.1146 29.9199 13.5638 28.9824 14.0195C28.7741 14.1107 28.5983 14.1497
        28.4551 14.1367C28.3118 14.1107 28.1621 14.026 28.0059 13.8828C27.6543 13.5703 27.3027 13.2708 26.9512 12.9844C26.5996 
        12.6849 26.248 12.3854 25.8965 12.0859C25.7533 11.9557 25.6035 11.8711 25.4473 11.832C25.291 11.793 25.1217 11.819 24.9395
        11.9102C24.7702 12.0013 24.6595 12.125 24.6074 12.2812C24.5553 12.4245 24.5293 12.5872 24.5293 12.7695C24.5423 13.1732 
        24.5488 13.5703 24.5488 13.9609C24.5488 14.3516 24.5684 14.7422 24.6074 15.1328C24.6335 15.4583 24.5879 15.7188 24.4707
        15.9141C24.3535 16.1094 24.1322 16.2461 23.8066 16.3242C23.4811 16.4023 23.2598 16.5456 23.1426 16.7539C23.0384 16.9622
        22.9863 17.2227 22.9863 17.5352C22.9863 18.4466 22.9928 19.3581 23.0059 20.2695C23.0059 21.181 23.0059 22.0924 23.0059 
        23.0039C23.0059 23.9154 23.0059 24.8268 23.0059 25.7383C23.0059 26.6497 23.0059 27.5547 23.0059 28.4531C23.0059 28.8568 
        23.11 29.1302 23.3184 29.2734C23.5267 29.4167 23.8197 29.4232 24.1973 29.293C24.7311 29.1107 25.265 28.9284 25.7988 
        28.7461C26.3327 28.5508 26.8665 28.362 27.4004 28.1797C27.9342 27.9844 28.4681 27.7956 29.002 27.6133C29.5358 27.431 
        30.0697 27.2422 30.6035 27.0469C30.877 26.9427 31.0658 26.806 31.1699 26.6367C31.2871 26.4544 31.3066 26.2526 31.2285 
        26.0312ZM37.8691 24.6836H37.8887C37.7324 24.7487 37.6087 24.7682 37.5176 24.7422C37.4395 24.7031 37.4004 24.5924 37.4004 
        24.4102C37.3874 23.5898 37.4915 22.8151 37.7129 22.0859C37.9473 21.3568 38.429 20.7253 39.1582 20.1914C38.429 19.6706 
        37.9798 19.1042 37.8105 18.4922C37.6543 17.8672 37.791 17.2682 38.2207 16.6953C38.5853 16.2005 39.0866 15.9141 39.7246 
        15.8359C40.3757 15.7448 40.9421 15.8945 41.4238 16.2852C41.9837 16.7279 42.2637 17.2682 42.2637 17.9062C42.2767 18.5443 
        42.0098 19.1823 41.4629 19.8203C41.8796 20.1198 42.1595 20.4779 42.3027 20.8945C42.459 21.3112 42.5566 21.7604 42.5957
        22.2422C42.5957 22.3203 42.5566 22.418 42.4785 22.5352C42.4134 22.6393 42.3483 22.7109 42.2832 22.75C41.541 23.0755 40.7988
        23.401 40.0566 23.7266C39.3275 24.0391 38.5983 24.3581 37.8691 24.6836ZM30.3887 20.6992L30.4082 20.6797C30.9421 20.9922 
        31.2806 21.3893 31.4238 21.8711C31.5671 22.3398 31.6387 22.8346 31.6387 23.3555C31.6387 23.4206 31.5996 23.4922 31.5215 
        23.5703C31.4564 23.6354 31.3848 23.681 31.3066 23.707C30.5514 23.9805 29.7962 24.2539 29.041 24.5273C28.2858 24.7878 
        27.5306 25.0547 26.7754 25.3281C26.5801 25.4062 26.4499 25.4128 26.3848 25.3477C26.3327 25.2695 26.3132 25.1458 26.3262 
        24.9766C26.3522 24.2474 26.4889 23.5638 26.7363 22.9258C26.9837 22.2878 27.4199 21.7279 28.0449 21.2461C27.6413 21.0508 
        27.3092 20.8034 27.0488 20.5039C26.7884 20.2044 26.6322 19.8333 26.5801 19.3906C26.554 19.0781 26.5801 18.7852 26.6582 
        18.5117C26.7363 18.2383 26.873 17.9779 27.0684 17.7305C27.4199 17.2747 27.9017 17.0143 28.5137 16.9492C29.1257 16.8711 
        29.6725 17.0143 30.1543 17.3789C30.571 17.6914 30.8444 18.1732 30.9746 18.8242C31.1178 19.4622 30.9225 20.0872 30.3887 
        20.6992ZM45.0566 11.207H45.0176C45.3952 11.0768 45.6882 11.0833 45.8965 11.2266C46.1048 11.3698 46.209 11.6432 46.209 
        12.0469C46.209 12.9453 46.209 13.8503 46.209 14.7617C46.209 15.6732 46.209 16.5846 46.209 17.4961C46.209 18.4076 46.2155 
        19.319 46.2285 20.2305C46.2285 21.1419 46.2285 22.0534 46.2285 22.9648C46.2415 23.2773 46.1895 23.5378 46.0723 23.7461C45.9551 
        23.9544 45.7402 24.0977 45.4277 24.1758C45.1022 24.2539 44.8809 24.3906 44.7637 24.5859C44.6465 24.7812 44.6009 25.0417 44.627 
        25.3672C44.666 25.7578 44.6855 26.1484 44.6855 26.5391C44.6855 26.9297 44.6921 27.3268 44.7051 27.7305C44.7181 27.9128 44.6921 
        28.082 44.627 28.2383C44.5749 28.3815 44.4642 28.4987 44.2949 28.5898C44.1126 28.681 43.9434 28.707 43.7871 28.668C43.6309 28.6159 
        43.4811 28.5312 43.3379 28.4141C42.9863 28.1146 42.6283 27.8216 42.2637 27.5352C41.9121 27.2357 41.5671 26.9297 41.2285 26.6172C41.0723 
        26.474 40.9225 26.3958 40.7793 26.3828C40.6361 26.3568 40.4603 26.3893 40.252 26.4805C39.3145 26.9362 38.377 27.3854 37.4395
        27.8281C36.502 28.2708 35.5579 28.7135 34.6074 29.1562C34.0996 29.3906 33.7285 29.4297 33.4941 29.2734C33.2598 29.1302 33.1426 
        28.7721 33.1426 28.1992C33.1426 27.6914 33.1426 27.1901 33.1426 26.6953C33.1426 26.1875 33.1426 25.6862 33.1426 25.1914C33.1035 
        25.1784 33.071 25.1654 33.0449 25.1523C33.0319 25.1393 33.0189 25.1328 33.0059 25.1328C32.498 25.1068 32.2116 25.0352 32.1465 
        24.918C32.0814 24.7878 32.1855 24.5078 32.459 24.0781C32.6283 23.8177 32.791 23.5573 32.9473 23.2969C33.1165 23.0365 33.2858 
        22.776 33.4551 22.5156C33.5853 22.3203 33.7155 22.2227 33.8457 22.2227C33.9889 22.2227 34.1257 22.3203 34.2559 22.5156C34.4642 
        22.8021 34.666 23.0885 34.8613 23.375C35.0566 23.6615 35.2454 23.9544 35.4277 24.2539C35.4928 24.3581 35.5449 24.4753 35.584
        24.6055C35.623 24.7227 35.623 24.8268 35.584 24.918C35.5579 24.9831 35.4798 25.0286 35.3496 25.0547C35.2194 25.0677 35.1022 
        25.0938 34.998 25.1328C34.959 25.1328 34.9134 25.1328 34.8613 25.1328C34.8223 25.1328 34.7767 25.1328 34.7246 25.1328V27.418C35.3236 
        27.1315 35.916 26.8516 36.502 26.5781C37.0879 26.3047 37.6673 26.0312 38.2402 25.7578C38.5788 25.6016 38.9173 25.4453 39.2559 
        25.2891C39.6074 25.1328 39.9525 24.9635 40.291 24.7812C40.5514 24.651 40.7923 24.6055 41.0137 24.6445C41.235 24.6706 41.4564 24.7878 
        41.6777 24.9961C41.8991 25.1914 42.1335 25.3932 42.3809 25.6016C42.6283 25.7969 42.8822 26.0052 43.1426 26.2266C43.1426 25.875 43.1426 
        25.543 43.1426 25.2305C43.1556 24.918 43.1556 24.6055 43.1426 24.293C43.1165 24.0326 43.1491 23.8112 43.2402 23.6289C43.3444 23.4336 
        43.5267 23.3099 43.7871 23.2578C44.1908 23.1536 44.4512 22.9779 44.5684 22.7305C44.6986 22.4831 44.7572 22.1576 44.7441 21.7539C44.7311 
        21.0768 44.7181 20.3932 44.7051 19.7031C44.7051 19.013 44.7051 18.3229 44.7051 17.6328C44.7051 16.9427 44.7051 16.2526 44.7051 
        15.5625C44.7051 14.8724 44.7051 14.1823 44.7051 13.4922V12.9453C44.1973 13.1146 43.7155 13.2839 43.2598 13.4531C42.804 13.6094 
        42.3483 13.7656 41.8926 13.9219C41.4368 14.0911 40.9811 14.2539 40.5254 14.4102C40.0697 14.5664 39.6139 14.7227 39.1582 14.8789C38.8587 
        14.9831 38.6113 15.0026 38.416 14.9375C38.2337 14.8594 38.097 14.7031 38.0059 14.4688C37.9277 14.2474 37.9408 14.0521 38.0449 
        13.8828C38.1621 13.7005 38.3639 13.5573 38.6504 13.4531C39.1842 13.2578 39.7181 13.069 40.252 12.8867C40.7858 12.7044 41.3197 12.5221 
        41.8535 12.3398C42.3874 12.1445 42.9212 11.9492 43.4551 11.7539C43.9889 11.5716 44.5228 11.3893 45.0566 11.207ZM50.9941 
        14.293H51.0137C51.0267 14.6055 51.0267 14.918 51.0137 15.2305C51.0137 15.5299 51.0137 15.8359 51.0137 16.1484C51.0137 16.4219 
        51.0137 16.7018 51.0137 16.9883C51.0137 17.2617 51.0137 17.5352 51.0137 17.8086C51.0007 18.056 50.929 18.2513 50.7988 18.3945C50.6816 
        18.5378 50.4993 18.5964 50.252 18.5703C49.9004 18.5182 49.5358 18.4661 49.1582 18.4141C48.7936 18.349 48.429 18.2969 48.0645 
        18.2578C47.8301 18.2188 47.6543 18.1276 47.5371 17.9844C47.4199 17.8411 47.3548 17.6523 47.3418 17.418V17.3203C47.3548 16.1094 
        47.4004 15.4388 47.4785 15.3086C47.5697 15.1784 48.1296 14.8529 49.1582 14.332C49.3145 14.2539 49.4772 14.1693 49.6465 14.0781C49.8158 
        13.987 49.985 13.9023 50.1543 13.8242C50.4277 13.694 50.6296 13.668 50.7598 13.7461C50.903 13.8112 50.9811 13.9935 50.9941 14.293Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

ASLIcon.propTypes = {
  fill: PropTypes.string,
};

export default ASLIcon;
