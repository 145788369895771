import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export default function useLanguage() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { i18n, ...translation } = useTranslation();
  const lang = searchParams.get("lang");
  const [language, _setLanguage] = useState(i18n.language);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    if (lang?.includes("en")) {
      const queryParams = new URLSearchParams(searchParams.entries());
      queryParams.delete("lang");
      navigate(`${pathname}?${queryParams.toString()}`, { replace: true });
    } else if (!lang) {
      if (language?.includes("en")) {
        return;
      } else {
        _setLanguage("en");
      }
    } else if (lang !== language) {
      _setLanguage(lang);
    }
  }, [pathname, lang, language, searchParams, navigate]);

  function toggleLanguage() {
    const newLanguage = language.includes("en") ? "es" : "en";
    setLanguage(newLanguage);
  }

  function setLanguage(lang) {
    _setLanguage(lang);
    const queryParams = new URLSearchParams(searchParams.entries());
    if (lang.includes("en")) {
      queryParams.delete("lang");
    } else {
      queryParams.set("lang", lang);
    }
    navigate(`${pathname}?${queryParams.toString()}`);
  }

  return { ...translation, i18n, language, toggleLanguage, setLanguage };
}
