import { SvgIcon } from "@mui/material";
import theme from "../../themes/theme";

const LanguageIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="20 5 30 30">
      <path
        d="M33.5 10.25C34.8802 10.25 36.1758 10.5104 37.3867 11.0312C38.5977 11.5521 
        39.6523 12.2682 40.5508 13.1797C41.4622 14.0781 42.1849 15.1393 42.7188 
        16.3633C43.2396 17.5742 43.5 18.8698 43.5 20.25C43.5 21.6302 43.2396 22.9258 
        42.7188 24.1367C42.1979 25.3607 41.4818 26.4284 40.5703 27.3398C39.6719 28.2383 
        38.6107 28.9479 37.3867 29.4688C36.1758 29.9896 34.8802 30.25 33.5 30.25C32.1198 
        30.25 30.8242 29.9896 29.6133 29.4688C28.3893 28.9479 27.3216 28.2383 26.4102 27.3398C25.5117 
        26.4284 24.8021 25.3607 24.2812 24.1367C23.7604 22.9258 23.5 21.6302 23.5 20.25C23.5 18.8698 
        23.7604 17.5742 24.2812 16.3633C24.8021 15.1393 25.5117 14.0781 26.4102 13.1797C27.3216 12.2682 
        28.3893 11.5521 29.6133 11.0312C30.8242 10.5104 32.1198 10.25 33.5 10.25ZM41.8203 20.25C41.8203 
        19.8073 41.7878 19.3776 41.7227 18.9609C41.6576 18.5312 41.5599 18.1276 41.4297 17.75H38.5586V17.6719C38.6758 
        18.0885 38.7604 18.5117 38.8125 18.9414C38.8776 19.3581 38.9102 19.7943 38.9102 20.25C38.9102 20.6927 
        38.8776 21.1289 38.8125 21.5586C38.7604 21.9753 38.6823 22.3724 38.5781 22.75H41.4297C41.5599 22.3594 
        41.6576 21.9557 41.7227 21.5391C41.7878 21.1224 41.8203 20.6927 41.8203 20.25ZM35.668 12.2031L35.6484 
        12.1836C36.1172 12.7565 36.5469 13.3685 36.9375 14.0195C37.3411 14.6576 37.7057 15.3477 38.0312 
        16.0898H40.707L40.6875 16.0508C40.4141 15.582 40.1016 15.1523 39.75 14.7617C39.3984 14.3581 39.0078 
        13.9935 38.5781 13.668C38.1615 13.3424 37.7057 13.056 37.2109 12.8086C36.7292 12.5612 36.2148 12.3594 
        35.668 12.2031ZM36.8594 22.75C36.9766 22.3594 37.0677 21.9557 37.1328 21.5391C37.2109 21.1224 37.25 
        20.6927 37.25 20.25C37.25 19.7943 37.2109 19.3581 37.1328 18.9414C37.0677 18.5247 36.9766 18.1276 
        36.8594 17.75H30.1406C30.0234 18.1406 29.9258 18.5443 29.8477 18.9609C29.7826 19.3776 29.75 19.8073 
        29.75 20.25C29.75 20.7057 29.7826 21.1419 29.8477 21.5586C29.9258 21.9753 30.0234 22.3724 30.1406 
        22.75H36.8594ZM36.1953 24.4102V24.4297C36.1953 24.4297 35.9154 24.4297 35.3555 24.4297C34.7956 24.4297 
        34.1771 24.4297 33.5 24.4297C32.8359 24.4297 32.224 24.4297 31.6641 24.4297C31.1042 24.4297 30.8242 
        24.4297 30.8242 24.4297L30.8633 24.5078C31.2148 25.1979 31.612 25.8555 32.0547 26.4805C32.4974 27.1055 
        32.9792 27.6914 33.5 28.2383C34.0208 27.6784 34.5026 27.0859 34.9453 26.4609C35.401 25.8229 35.8177 
        25.1393 36.1953 24.4102ZM30.8047 16.0898V16.0703H36.1758L36.1367 15.9922C35.7852 15.3021 35.388 14.6445 
        34.9453 14.0195C34.5026 13.3945 34.0208 12.8086 33.5 12.2617C32.9792 12.8216 32.4909 13.4206 32.0352 
        14.0586C31.5924 14.6836 31.1823 15.3607 30.8047 16.0898ZM31.2734 12.2227V12.2031C30.7526 12.3594 30.2513 
        12.5612 29.7695 12.8086C29.2878 13.043 28.832 13.3294 28.4023 13.668C27.9857 13.9935 27.6016 14.3581 27.25 
        14.7617C26.8854 15.1654 26.5664 15.6016 26.293 16.0703H28.9883L29.0273 15.9727C29.3268 15.2826 29.6719 
        14.625 30.0625 14C30.4531 13.362 30.8763 12.7695 31.332 12.2227H31.2734ZM25.1602 20.25C25.1602 20.6927 
        25.1927 21.1289 25.2578 21.5586C25.3229 21.9753 25.4206 22.3724 25.5508 22.75H28.4219V22.8281C28.3177 
        22.4115 28.2331 21.9948 28.168 21.5781C28.1029 21.1484 28.0703 20.7057 28.0703 20.25C28.0833 19.8073 
        28.1159 19.3776 28.168 18.9609C28.2201 18.5312 28.3047 18.1276 28.4219 17.75H25.5508C25.4206 18.1406 
        25.3229 18.5443 25.2578 18.9609C25.1927 19.3776 25.1602 19.8073 25.1602 20.25ZM31.332 28.2969L31.3711 
        28.3164C30.9023 27.7435 30.4661 27.138 30.0625 26.5C29.6719 25.849 29.3138 25.1523 28.9883 24.4102H26.293L26.332 
        24.4492C26.5924 24.918 26.8984 25.3477 27.25 25.7383C27.6016 26.1419 27.9922 26.5065 28.4219 26.832C28.8516 
        27.1576 29.3073 27.444 29.7891 27.6914C30.2839 27.9388 30.7982 28.1406 31.332 28.2969ZM35.7266 28.2773V28.2969C36.2474 
        28.1406 36.7487 27.9388 37.2305 27.6914C37.7122 27.444 38.1615 27.1576 38.5781 26.832C39.0078 26.5065 39.3984 
        26.1419 39.75 25.7383C40.1146 25.3346 40.4336 24.8984 40.707 24.4297H38.0117L37.9727 24.5273C37.6732 25.2174 
        37.3281 25.8815 36.9375 26.5195C36.5469 27.1445 36.1237 27.7305 35.668 28.2773H35.7266Z"
        fill={theme.palette.secondary.main}
      />
    </SvgIcon>
  );
};

export default LanguageIcon;
