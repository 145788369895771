import * as Yup from "yup";

const getValidationSchema = (t) => {
  return Yup.object().shape({
    zipCode: Yup.string()
      .matches(/^\d*$/, t("zipCodeQuestion.requiredMessage"))
      .length(5, t("zipCodeQuestion.requiredMessage"))
      .required(t("zipCodeQuestion.requiredMessage")),
    thoughtsOfSuicide: Yup.string().required(t("questionText.requiredField")),
    upsetAmount: Yup.string().required(t("questionText.requiredField")),
    isLGBTQ: Yup.boolean().notRequired(),
    name: Yup.string().notRequired(),
    age: Yup.string()
      .matches(/^\d*$/, t("ageQuestion.requiredMessage"))
      .notRequired(),
    gender: Yup.string().notRequired(),
    gender_unlisted: Yup.string().notRequired(),
    transgender: Yup.string().notRequired(),
    mainConcerns: Yup.array().notRequired(),
    mainConcerns_unlisted: Yup.string().notRequired(),
    phoneNumber: Yup.string()
      .notRequired()
      .nullable()
      .test(
        "valid-length",
        t("phoneNumberQuestion.lengthOf10Message"),
        function (value) {
          return value.length === 0 || value.length === 10;
        },
      ),
  });
};

export default getValidationSchema;
