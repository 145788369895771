export function replaceMainSiteLinkHostname(url = "<%= host %>") {
  const VITE_MAINSITE_HOST =
    import.meta.env.VITE_MAINSITE_HOST || "https://988lifeline.org";

  return url.replace("<%= host %>", VITE_MAINSITE_HOST);
}

export function serializeSurvey({
  interactionIdKey: interaction_id_key,
  modality,
  zipCode: postal_code,
  thoughtsOfSuicide: thoughts_of_suicide,
  upsetAmount: upset_amount,
  isLGBTQ: is_trevor,
  name,
  age,
  gender,
  gender_unlisted,
  transgender,
  mainConcerns: concern,
  mainConcerns_unlisted: concern_unlisted,
  phoneNumber: contact_details,
  locale,
  submitTimestamp: submit_timestamp,
  recaptchaToken: recaptcha_token,
}) {
  return {
    modality,
    locale,
    postal_code,
    submit_timestamp,
    recaptcha_token,
    ...(interaction_id_key && { interaction_id_key }),
    ...(name && { name }),
    ...(age && { age }),
    ...(gender && { gender }),
    ...(gender_unlisted && { gender_unlisted }),
    ...(transgender && { transgender }),
    ...(is_trevor && { is_trevor }),
    ...(concern.length > 0 && { concern }),
    ...(concern_unlisted && { concern_unlisted }),
    ...(thoughts_of_suicide && { thoughts_of_suicide }),
    ...(upset_amount && { upset_amount }),
    ...(contact_details && { contact_details }),
  };
}
