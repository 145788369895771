import CloseIcon from "./icons/CloseIcon";
import {
  Box,
  Button,
  Typography,
  Container,
  Link,
  IconButton,
  useTheme,
} from "@mui/material";
import cookie from "js-cookie";
import PropTypes from "prop-types";
import { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import CheckmarkDialogueIcon from "./icons/CheckmarkDialogueIcon";
import { replaceMainSiteLinkHostname } from "../services/Utils";
import {
  COOKIE_CONSENT_KEY,
  GA_TRACKING_ID,
  DOMAIN,
} from "../configs/googleAnalyticsConfig";

const CookieConsentBanner = ({ onGtagLoad }) => {
  const theme = useTheme();
  const [showBanner, setShowBanner] = useState(false);
  const { t } = useTranslation();
  const scriptLoadedRef = useRef(false);

  const loadGoogleAnalytics = useCallback(() => {
    if (scriptLoadedRef.current) return;
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", GA_TRACKING_ID);
      onGtagLoad(gtag);
      scriptLoadedRef.current = true;
    };
    document.head.appendChild(script);
  }, [onGtagLoad]);

  useEffect(() => {
    const consentCookie = cookie.get(COOKIE_CONSENT_KEY);

    if (consentCookie === "accepted") {
      loadGoogleAnalytics();
    } else if (!consentCookie) {
      setShowBanner(true);
    }
  }, [loadGoogleAnalytics, setShowBanner]);

  const handleAccept = () => {
    setShowBanner(false);
    cookie.set(COOKIE_CONSENT_KEY, "accepted", {
      expires: 365,
      domain: DOMAIN,
    });
    loadGoogleAnalytics();
  };

  const handleReject = () => {
    setShowBanner(false);
    cookie.set(COOKIE_CONSENT_KEY, "rejected", {
      expires: 365,
      domain: DOMAIN,
    });
  };

  const handleClose = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: theme.palette.secondary.dark,
        pb: 3,
        zIndex: 1300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "0 -1px 2px 0 rgba(0, 0, 0, 0.25)",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2.5,
            mb: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CheckmarkDialogueIcon
              fill={theme.palette.common.white}
              sx={{ fontSize: 28, mt: 0.4 }}
            />
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: theme.palette.common.white,
              }}
            >
              {t("cookieConsentBanner.title")}
            </Typography>
          </Box>
          <IconButton
            sx={{
              color: theme.palette.secondary.dark,
              backgroundColor: "transparent",
            }}
            onClick={handleClose}
          >
            <CloseIcon enableHover={true} />
          </IconButton>
        </Box>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.common.white, lineHeight: 1.8 }}
        >
          {t("cookieConsentBanner.message")}
          <Link
            href={replaceMainSiteLinkHostname(t("footer.links.privacy.url"))}
            target="_blank"
            sx={{
              color: theme.palette.common.white,
              textDecoration: "underline",
              fontWeight: "bold",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
          >
            {t("cookieConsentBanner.privacyPolicy")}
          </Link>
          .
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "right", gap: 1 }}>
          <Button
            variant="text"
            onClick={handleAccept}
            sx={{
              fontSize: 16,
              color: theme.palette.primary.light,
              textTransform: "none",
              fontWeight: "bold",
              bgcolor: "transparent",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                bgcolor: "transparent",
                color: theme.palette.pink300,
              },
              "&:focus": {
                textDecoration: "underline",
                bgcolor: "transparent",
              },
            }}
          >
            {t("cookieConsentBanner.accept")}
          </Button>
          <Button
            variant="text"
            onClick={handleReject}
            sx={{
              fontSize: 16,
              color: theme.palette.common.white,
              textTransform: "none",
              fontWeight: "bold",
              bgcolor: "transparent",
              textDecoration: "underline",
              "&:hover": {
                textDecoration: "underline",
                bgcolor: "transparent",
                color: theme.palette.pink300,
              },
              "&:focus": {
                textDecoration: "underline",
                bgcolor: "transparent",
              },
            }}
          >
            {t("cookieConsentBanner.reject")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

CookieConsentBanner.propTypes = {
  onGtagLoad: PropTypes.func.isRequired,
};

export default CookieConsentBanner;
