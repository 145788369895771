import { RECAPTCHA_PUBLIC_KEY } from "../configs/recaptchaConfig";

const useRecaptcha = () => {
  const executeRecaptcha = (action) => {
    return new Promise((resolve, reject) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(RECAPTCHA_PUBLIC_KEY, { action })
          .then((token) => {
            resolve(token);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  };

  return { executeRecaptcha };
};

export default useRecaptcha;
