import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

const CallIcon = ({ fill = "inherit", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="25 5 10 32">
      <path
        d="M33.3398 11.0898C33.3398 10.8555 33.418 10.6602 33.5742 10.5039C33.7435 10.3346 33.9453 10.25 34.1797
        10.25C35.3255 10.25 36.4062 10.4714 37.4219 10.9141C38.4245 11.3438 39.3034 11.9362 40.0586 12.6914C40.8138
        13.4466 41.4128 14.332 41.8555 15.3477C42.2852 16.3633 42.5065 17.444 42.5195 18.5898C42.5195 18.8242
        42.4349 19.026 42.2656 19.1953C42.1094 19.3516 41.9141 19.4297 41.6797 19.4297C41.4453 19.4297 41.2435
        19.3516 41.0742 19.1953C40.918 19.026 40.8398 18.8242 40.8398 18.5898C40.8398 17.6654 40.6641 16.7995
        40.3125 15.9922C39.9609 15.1849 39.4857 14.4818 38.8867 13.8828C38.2878 13.2708 37.5781 12.7956 36.7578
        12.457C35.9505 12.1055 35.0911 11.9297 34.1797 11.9297C33.9453 11.9297 33.7435 11.8516 33.5742
        11.6953C33.418 11.526 33.3398 11.3242 33.3398 11.0898ZM34.1602 15.25C35.0846 15.25 35.8724 15.5755
        36.5234 16.2266C37.1745 16.8776 37.5 17.6654 37.5 18.5898C37.5 18.8242 37.5781 19.026 37.7344
        19.1953C37.9036 19.3516 38.1055 19.4297 38.3398 19.4297C38.5742 19.4297 38.7695 19.3516 38.9258 
        19.1953C39.0951 19.026 39.1797 18.8242 39.1797 18.5898C39.1797 17.8997 39.0495 17.2487 38.7891 
        16.6367C38.5286 16.0378 38.1706 15.5104 37.7148 15.0547C37.2591 14.599 36.7253 14.2409 36.1133 
        13.9805C35.5143 13.7201 34.8698 13.5898 34.1797 13.5898C33.9453 13.5898 33.7435 13.6745 33.5742 
        13.8438C33.418 14 33.3398 14.1953 33.3398 14.4297C33.3398 14.6641 33.418 14.8659 33.5742 15.0352C33.7435 
        15.1914 33.9453 15.2695 34.1797 15.2695L34.1602 15.25ZM41.7578 24.1953C41.9792 24.4297 42.1549 24.7031 
        42.2852 25.0156C42.4284 25.3281 42.5 25.6667 42.5 26.0312C42.5 26.3828 42.4284 26.7148 42.2852 27.0273C42.1549 
        27.3398 41.9792 27.6133 41.7578 27.8477L40.9961 28.7266C39.2904 30.3542 37.1419 30.7122 34.5508 29.8008C31.9596 
        28.8763 29.6094 27.3724 27.5 25.2891C25.4036 23.1927 23.8932 20.849 22.9688 18.2578C22.0443 15.6667 22.3828 
        13.5117 23.9844 11.793L24.9414 10.9531C25.1758 10.7448 25.4427 10.5755 25.7422 10.4453C26.0547 10.3151 26.3802 
        10.25 26.7188 10.25C27.0703 10.25 27.4023 10.3216 27.7148 10.4648C28.0404 10.5951 28.3203 10.7773 28.5547 
        11.0117C28.5677 11.0247 28.8281 11.3698 29.3359 12.0469C29.8568 12.7109 30.1172 13.043 30.1172 13.043C30.3385 
        13.2643 30.5143 13.5312 30.6445 13.8438C30.7747 14.1432 30.8398 14.4688 30.8398 14.8203C30.8398 15.1589 
        30.7747 15.4844 30.6445 15.7969C30.5143 16.0964 30.3385 16.3633 30.1172 16.5977C30.1172 16.5977 29.9544 
        16.7995 29.6289 17.2031C29.3034 17.6068 29.1406 17.8086 29.1406 17.8086C29.4271 18.4727 29.7591 19.0911 
        30.1367 19.6641C30.5273 20.25 30.9635 20.7904 31.4453 21.2852C31.9401 21.7669 32.474 22.2031 33.0469 
        22.5938C33.6198 22.9714 34.2253 23.2969 34.8633 23.5703L34.9414 23.6094L36.1719 22.6328C36.3932 22.4115 
        36.6536 22.2357 36.9531 22.1055C37.2656 21.9753 37.5977 21.9102 37.9492 21.9102C38.3008 21.9102 38.6263 
        21.9753 38.9258 22.1055C39.2383 22.2357 39.5117 22.4115 39.7461 22.6328C39.7461 22.6328 40.0781 22.8932 
        40.7422 23.4141C41.4062 23.9219 41.7448 24.1823 41.7578 24.1953ZM40.8203 26.0312C40.8203 25.9141 40.8008 
        25.8034 40.7617 25.6992C40.7227 25.5951 40.6706 25.4974 40.6055 25.4062C40.6055 25.4062 40.2669 25.1523 
        39.5898 24.6445C38.9258 24.1237 38.5872 23.8568 38.5742 23.8438C38.4961 23.7656 38.3984 23.707 38.2812 
        23.668C38.1641 23.6159 38.0469 23.5898 37.9297 23.5898C37.7995 23.5898 37.6758 23.6159 37.5586 23.668C37.4544 
        23.707 37.3568 23.7656 37.2656 23.8438C37.2656 23.8568 36.9792 24.0911 36.4062 24.5469C35.8464 24.9896 35.5664 
        25.2109 35.5664 25.2109C35.5013 25.263 35.4232 25.3086 35.332 25.3477C35.2409 25.3867 35.1497 25.4062 35.0586 
        25.4062C35.0065 25.4062 34.9544 25.3997 34.9023 25.3867C34.8503 25.3737 34.7982 25.3607 34.7461 25.3477C33.8997 
        25.0221 33.099 24.6185 32.3438 24.1367C31.6016 23.6549 30.918 23.1081 30.293 22.4961C29.668 21.8711 29.1146 
        21.1875 28.6328 20.4453C28.151 19.7161 27.7539 18.9349 27.4414 18.1016L27.4219 18.0039C27.3958 17.9648 27.3763 
        17.9193 27.3633 17.8672C27.3503 17.8151 27.3438 17.7565 27.3438 17.6914C27.3438 17.6003 27.3568 17.5091 27.3828 
        17.418C27.4219 17.3268 27.474 17.2487 27.5391 17.1836C27.5391 17.1836 27.7604 16.9036 28.2031 16.3438C28.6589 
        15.7708 28.8932 15.4844 28.9062 15.4844C28.9844 15.3932 29.043 15.2956 29.082 15.1914C29.1341 15.0742 29.1602 
        14.9505 29.1602 14.8203C29.1602 14.7031 29.1341 14.5924 29.082 14.4883C29.043 14.3711 28.9844 14.2669 28.9062 
        14.1758C28.8932 14.1628 28.6263 13.8242 28.1055 13.1602C27.5977 12.4831 27.3438 12.1445 27.3438 12.1445C27.2526 
        12.0794 27.1549 12.0273 27.0508 11.9883C26.9466 11.9362 26.8359 11.9102 26.7188 11.9102C26.6016 11.9102 26.4844 
        11.9362 26.3672 11.9883C26.25 12.0273 26.1523 12.0924 26.0742 12.1836L25.1172 13.0039C23.9453 14.4232 23.8151 
        16.2005 24.7266 18.3359C25.638 20.4714 26.9987 22.418 28.8086 24.1758C30.6315 25.9336 32.6042 27.2292 34.7266 
        28.0625C36.849 28.9089 38.5286 28.7461 39.7656 27.5742L40.5273 26.6953C40.6185 26.6172 40.6901 26.5195 40.7422 
        26.4023C40.7943 26.2852 40.8203 26.1549 40.8203 26.0117V26.0312Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

CallIcon.propTypes = {
  fill: PropTypes.string,
};

export default CallIcon;
