import {
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  TextField,
  Box,
} from "@mui/material";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import theme from "../themes/theme";

const SurveyPillQuestion = ({
  mainLabelText,
  subLabelText,
  options,
  name,
  control,
  spacingTop,
  spacingBottom,
  maxCharacters = 100,
}) => {
  const {
    field: { onChange, value, ref, onBlur },
  } = useController({
    name,
    control,
  });

  const requiresCustomInput = options.some(
    (option) => value.includes(option.value) && option.customInput,
  );

  const { t } = useTranslation();

  const {
    field: {
      onChange: customInputChange,
      value: customInputValue,
      ref: customInputRef,
      onBlur: customInputBlur,
    },
  } = useController({
    name: `${name}_unlisted`,
    control,
  });

  const handleSelectionChange = (event, newSelection) => {
    onChange(newSelection);
  };

  return (
    <Box sx={{ marginTop: spacingTop, marginBottom: spacingBottom }}>
      <Typography variant="h2">{mainLabelText}</Typography>
      <Typography
        sx={{
          fontStyle: "italic",
          fontSize: "14px",
          mb: 1,
          color: theme.palette.secondary.main,
        }}
      >
        {subLabelText}
      </Typography>
      <ToggleButtonGroup
        ref={ref}
        value={value}
        onChange={handleSelectionChange}
        onBlur={onBlur}
        sx={{ display: "flex", flexWrap: "wrap" }}
      >
        {options.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            sx={(theme) => ({
              ...theme.typography.pillText,
              mx: 1.5,
              my: 0.5,
              px: 2.5,
              py: 1,
              borderRadius: "20px",
              textTransform: "none",
              color: theme.palette.secondary.dark,
              bgcolor: theme.palette.common.white,
              "&.MuiToggleButtonGroup-grouped": {
                borderRadius: "25px",
                borderWidth: "1.2px",
              },
              "&:hover": {
                color: theme.palette.secondary.main,
                bgcolor: theme.palette.secondary.light,
                transition: "background-color 0.3s, border-color 0.3s",
              },
              "&:not(.Mui-selected)": {
                borderColor: theme.palette.secondary.dark,
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
              },
              "&.Mui-selected": {
                color: "white",
                bgcolor: theme.palette.primary.light,
                borderColor: theme.palette.primary.light,
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                },
              },
              "&:first-of-type": {
                marginLeft: 0,
              },
            })}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {requiresCustomInput && (
        <Box
          sx={{
            bgcolor: theme.palette.primary.light,
            borderRadius: "5px",
            p: 2,
            mt: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <TextField
            ref={customInputRef}
            value={customInputValue}
            onChange={customInputChange}
            onBlur={customInputBlur}
            placeholder={t("questionText.clarify")}
            fullWidth
            focused
            InputProps={{
              sx: { fontSize: 16 },
              inputProps: { maxLength: maxCharacters },
            }}
            InputLabelProps={{ sx: { fontSize: 16 } }}
            sx={{
              bgcolor: "white",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          />
          <Typography
            sx={{
              color: "white",
              fontSize: "14px",
              mt: 1,
              alignSelf: "flex-start",
            }}
          >
            {customInputValue.length === 0
              ? `${maxCharacters} ${t("mainConcernsQuestion.maximumCharacters")}`
              : customInputValue.length === maxCharacters - 1
                ? `${maxCharacters - customInputValue.length} ${t("mainConcernsQuestion.characterRemaining")}`
                : `${maxCharacters - customInputValue.length} ${t("mainConcernsQuestion.charactersRemaining")}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

SurveyPillQuestion.propTypes = {
  mainLabelText: PropTypes.string.isRequired,
  subLabelText: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      customInput: PropTypes.bool,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  spacingTop: PropTypes.number,
  spacingBottom: PropTypes.number,
  maxCharacters: PropTypes.number,
};

export default SurveyPillQuestion;
