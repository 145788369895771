import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import "../styles/fontFaces.css";
import useMediaQuery from "@mui/material/useMediaQuery";

const SurveyQuestion = ({
  errors,
  question,
  options,
  required,
  name,
  control,
  spacingTop,
  spacingBottom,
  maxCharacters = 50,
}) => {
  const {
    field: { ref, value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const requiresCustomInput = options.some(
    (option) => option.value === value && option.customInput,
  );

  const { t } = useTranslation();
  const {
    field: {
      ref: customInputRef,
      value: customInputValue,
      onChange: customInputChange,
      onBlur: customInputBlur,
    },
    fieldState: { error: customInputError },
  } = useController({
    name: `${name}_unlisted`,
    control,
  });

  const hasIncompleteRequiredQuestionError =
    (question === t("suicideQuestion.title") && errors.thoughtsOfSuicide) ||
    (question === t("upsetQuestion.title") && errors.upsetAmount);

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <FormControl
      fullWidth
      error={Boolean(error || (requiresCustomInput && customInputError))}
      sx={{
        marginTop: spacingTop,
        marginBottom: spacingBottom,
      }}
    >
      <FormLabel
        sx={{
          ...theme.typography.h2,
          "& .MuiFormLabel-asterisk": {
            color: "red",
          },
          "&.Mui-focused": {
            color: theme.palette.secondary.main,
          },
          "&.Mui-error": {
            color: theme.palette.darkRed,
          },
          color: hasIncompleteRequiredQuestionError
            ? theme.palette.darkRed
            : theme.palette.secondary.main,
          mb: 1,
        }}
      >
        {question}
        {required ? (
          <span
            style={{
              color: theme.palette.darkRed,
              fontFamily: "Public Sans",
              fontStyle: "italic",
              fontWeight: 400,
              fontSize: "13px",
            }}
          >
            {" "}
            {t("questionText.required")}
          </span>
        ) : null}
      </FormLabel>
      {hasIncompleteRequiredQuestionError && (
        <Box
          sx={{
            borderTop: `1px solid ${theme.palette.darkRed}`,
            borderBottom: `1px solid ${theme.palette.darkRed}`,
            height: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            backgroundColor: theme.palette.translucentWarmRed,
            mb: 1,
          }}
        >
          <Typography
            sx={{
              ml: "50px",
              color: theme.palette.darkRed,
              fontFamily: "Public Sans",
              fontWeight: 600,
              fontSize: "15px",
            }}
          >
            {t("questionText.requiredField")}
          </Typography>
        </Box>
      )}
      <RadioGroup onChange={onChange} value={value} ref={ref} onBlur={onBlur}>
        {options.map((option) => (
          <Box
            key={option.value}
            sx={{
              borderRadius: 2,
              mb: isDesktop ? 0 : 1,
              py: isDesktop ? "0px" : "5px",
              bgcolor:
                value === option.value ? theme.palette.primary.light : "white",
              pl: 1.5,
              pr: 1.5,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              boxSizing: "border-box",
              border: isDesktop
                ? "1px solid transparent"
                : `.25px solid ${theme.palette.secondary.main}`,
              "&:hover": isDesktop
                ? {
                    color: theme.palette.primary.main,
                    bgcolor:
                      value === option.value
                        ? theme.palette.primary.light
                        : theme.palette.secondary.light,
                    border:
                      value !== option.value
                        ? `1px solid ${theme.palette.primary.main}`
                        : "1px solid transparent",
                  }
                : {},
              transition: "none",
            }}
          >
            <FormControlLabel
              value={option.value}
              control={
                <Radio
                  disableRipple
                  sx={{
                    py: isDesktop ? "6px" : "9px",
                    color: theme.palette.secondary.dark,
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                />
              }
              label={option.label}
              sx={{
                "& .MuiTypography-root": {
                  color:
                    value === option.value
                      ? "white"
                      : theme.palette.secondary.dark,
                  fontWeight: value === option.value ? "700" : "400",
                  fontSize: 16,
                },
                width: "100%",
                mb: 0,
              }}
            />
            {value === option.value && option.customInput && (
              <Box sx={{ width: "100%", position: "relative", mt: 0, pb: 5 }}>
                <TextField
                  ref={customInputRef}
                  value={customInputValue}
                  onChange={customInputChange}
                  onBlur={customInputBlur}
                  placeholder={t("questionText.clarify")}
                  fullWidth
                  focused
                  InputProps={{
                    sx: { fontSize: 16 },
                    inputProps: { maxLength: maxCharacters },
                  }}
                  InputLabelProps={{ sx: { fontSize: 16 } }}
                  sx={{
                    bgcolor: "white",
                    borderRadius: "8px",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                    },
                    width: "calc(100% - 16px)",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "65px",
                    left: "10px",
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  {customInputValue.length === 0
                    ? `${maxCharacters} ${t("genderIdentityQuestion.maximumCharacters")}`
                    : customInputValue.length === maxCharacters - 1
                      ? `${maxCharacters - customInputValue.length} ${t("genderIdentityQuestion.characterRemaining")}`
                      : `${maxCharacters - customInputValue.length} ${t("genderIdentityQuestion.charactersRemaining")}`}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </RadioGroup>
      {requiresCustomInput && customInputError && (
        <FormHelperText>{customInputError.message}</FormHelperText>
      )}
    </FormControl>
  );
};

SurveyQuestion.propTypes = {
  errors: PropTypes.object,
  question: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      customInput: PropTypes.bool,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  required: PropTypes.bool,
  spacingTop: PropTypes.number,
  spacingBottom: PropTypes.number,
  maxCharacters: PropTypes.number,
};

export default SurveyQuestion;
