import {
  GENESYS_ENVIRONMENT,
  GENESYS_DEPLOYMENT_ID,
  GENESYS_SCRIPT_URL,
  GENESYS_DEBUG,
} from "../configs/genesysConfig.js";

(function (g, e, n, es, ys) {
  g["_genesysJs"] = e;
  g[e] =
    g[e] ||
    function () {
      (g[e].q = g[e].q || []).push(arguments);
    };
  g[e].t = 1 * new Date();
  g[e].c = es;
  ys = document.createElement("script");
  ys.async = 1;
  ys.src = n;
  ys.charset = "utf-8";
  document.head.appendChild(ys);
})(window, "Genesys", GENESYS_SCRIPT_URL, {
  environment: GENESYS_ENVIRONMENT,
  deploymentId: GENESYS_DEPLOYMENT_ID,
  debug: GENESYS_DEBUG,
});
