export const getChoices = (t) => {
  return {
    thoughtsOfSuicideChoices: [
      { label: t("suicideQuestion.yesCurrent"), value: "current" },
      { label: t("suicideQuestion.yesRecent"), value: "recent_past" },
      { label: t("suicideQuestion.no"), value: "none" },
    ],
    upsetScaleChoices: [
      { label: t("upsetQuestion.doingOk"), value: "ok" },
      { label: t("upsetQuestion.littleUpset"), value: "slightly_upset" },
      { label: t("upsetQuestion.moderatelyUpset"), value: "moderately_upset" },
      { label: t("upsetQuestion.veryUpset"), value: "very_upset" },
      { label: t("upsetQuestion.extremelyUpset"), value: "extremely_upset" },
    ],
    genderIdentityChoices: [
      { label: t("genderIdentityQuestion.male"), value: "male" },
      { label: t("genderIdentityQuestion.female"), value: "female" },
      { label: t("genderIdentityQuestion.agender"), value: "agender" },
      { label: t("genderIdentityQuestion.genderfluid"), value: "genderfluid" },
      {
        label: t("genderIdentityQuestion.nonBinaryNonConforming"),
        value: "nonbinary_nonconforming",
      },
      { label: t("genderIdentityQuestion.twoSpirit"), value: "two_spirit" },
      { label: t("genderIdentityQuestion.questioning"), value: "questioning" },
      {
        label: t("genderIdentityQuestion.declineToAnswer"),
        value: "decline_answer",
      },
      {
        label: t("genderIdentityQuestion.differentTerm"),
        value: "not_listed",
        customInput: true,
      },
    ],
    transgenderChoices: [
      { label: t("transgenderQuestion.yes"), value: "yes" },
      { label: t("transgenderQuestion.no"), value: "no" },
      { label: t("transgenderQuestion.notSure"), value: "not_sure" },
      { label: t("transgenderQuestion.declineAnswer"), value: "declined" },
    ],
    mainConcernChoices: [
      { label: t("mainConcernsQuestion.addictions"), value: "addictions" },
      { label: t("mainConcernsQuestion.anxiety"), value: "anxiety" },
      { label: t("mainConcernsQuestion.bullying"), value: "bullying_school" },
      { label: t("mainConcernsQuestion.depression"), value: "depression" },
      {
        label: t("mainConcernsQuestion.discrimination"),
        value: "discrimination",
      },
      {
        label: t("mainConcernsQuestion.eatingDisorder"),
        value: "eating_disorder",
      },
      { label: t("mainConcernsQuestion.familyIssues"), value: "family" },
      {
        label: t("mainConcernsQuestion.financialIssues"),
        value: "financial",
      },
      {
        label: t("mainConcernsQuestion.physicalHealth"),
        value: "physical_health",
      },
      { label: t("mainConcernsQuestion.abuse"), value: "abuse" },
      {
        label: t("mainConcernsQuestion.schoolProblems"),
        value: "school_problems",
      },
      {
        label: t("mainConcernsQuestion.relationshipIssues"),
        value: "relationship_issues",
      },
      { label: t("mainConcernsQuestion.selfHarm"), value: "self_harm" },
      { label: t("mainConcernsQuestion.sexuality"), value: "sexuality" },
      { label: t("mainConcernsQuestion.someoneElse"), value: "someone_else" },
      { label: t("mainConcernsQuestion.violence"), value: "violence" },
      {
        label: t("mainConcernsQuestion.other"),
        value: "not_listed",
        customInput: true,
      },
    ],
  };
};
