import { createTheme } from "@mui/material/styles";
import "../styles/fontFaces.css";

const theme = createTheme({
  typography: {
    fontFamily: "Public Sans, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: 26,
      fontWeight: 800,
      color: "#001A4E",
    },
    h2: {
      fontSize: 20,
      fontWeight: 800,
      color: "#001A4E",
    },
    h3: {
      fontSize: 23,
      fontWeight: 800,
      color: "white",
    },
    h4: {
      fontSize: 20,
      fontWeight: 800,
      color: "#0A1326",
    },
    h5: {
      fontSize: 14,
      fontWeight: 700,
      color: "#0278A0",
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 15,
    },
    pillText: {
      fontSize: 16,
    },
  },
  palette: {
    pink300: "#F454AC",
    slateGray: "#F7F7FF",
    neutralGray: "#CCCCCC",
    royalPurple: "#661BD3",
    warmRed: "#F04848",
    translucentWarmRed: "#FF66660D",
    lightRed: "#fef0f0",
    mediumRed: "#f44336",
    lightPurple: "#FBF9FE",
    darkRed: "#AC0610",
    borderColor: "#0C172E4D",
    primary: {
      light: "#4FC5DE", // Blue 100
      main: "#0278A0", // Blue 300
      contrast: "#0A1326",
    },
    secondary: {
      light: "#F2F9FF", // Navy 100
      main: "#001A4E", // Navy 300
      dark: "#0A1326", // Navy 900
      contrastText: "rgba(12, 23, 46, 0.3)", // Navy 900 - 30%
    },
    tertiary: {
      light: "#F7F7FF", // Plum 100
      main: "#6D0A64", // Plum 300
    },
  },
  breakpoints: {
    values: {
      // default breakpoint values
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // 988lifeline.org breakpoints
      full: 1440,
      larger: 1366,
      large: 1280,
      medium: 1024,
      small: 820,
      smaller: 660,
      tiny: 480,
    },
  },
  components: {
    MuiLink: {
      variants: [
        {
          props: { variant: "top-nav" },
          style: ({ theme }) => ({
            display: "flex",
            alignItems: "center",
            gap: 5,
            paddingTop: ".8rem",
            paddingBottom: ".6rem",
            textDecoration: "none",
            fontFamily: "Public Sans, sans serif",
            fontSize: 16,
            fontWeight: 700,
            color: theme.palette.primary.main,
            transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1)",
            "&:hover": {
              color: theme.palette.primary.contrast,
              backgroundColor: "transparent",
            },
          }),
        },
      ],
    },
    "MuiButton-text": {
      variants: [
        {
          props: { variant: "top-nav" },
          style: {
            textTransform: "none",
          },
        },
      ],
    },
  },
});

export default theme;
