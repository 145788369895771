import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../themes/theme";

const CheckmarkIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="20 10 30 25"
      style={{ fontSize: props.fontSize }}
    >
      <path
        d="M42.5195 14.5273C42.5195 14.6445 42.4935 14.7552 42.4414 14.8594C42.4023 14.9505 42.3438 15.0352 42.2656 15.1133L30.7617 
        26.6367C30.5404 26.8581 30.2734 27.0404 29.9609 27.1836C29.6615 27.3138 29.3359 27.3789 28.9844 27.3789C28.6328 27.3789 
        28.3073 27.3138 28.0078 27.1836C27.7083 27.0534 27.4414 26.8711 27.207 26.6367L22.7734 22.1836C22.6953 22.1055 22.6302 22.0143 
        22.5781 21.9102C22.5391 21.806 22.5195 21.6953 22.5195 21.5781C22.5195 21.474 22.5391 21.3698 22.5781 21.2656C22.6302 21.1615 
        22.6953 21.0703 22.7734 20.9922C22.8516 20.9271 22.9427 20.875 23.0469 20.8359C23.151 20.7839 23.2552 20.7578 23.3594 20.7578C23.4766 
        20.7578 23.5807 20.7839 23.6719 20.8359C23.776 20.875 23.8672 20.9271 23.9453 20.9922L28.3984 25.4648C28.4766 25.543 28.5677 25.6081 
        28.6719 25.6602C28.776 25.6992 28.8802 25.7188 28.9844 25.7188C29.1016 25.7188 29.2057 25.6992 29.2969 25.6602C29.401 25.6081 29.4922 
        25.543 29.5703 25.4648L41.0938 13.9414C41.1719 13.8633 41.263 13.8047 41.3672 13.7656C41.4714 13.7135 41.5755 13.6875 41.6797 13.6875C41.7969 
        13.6875 41.9076 13.7135 42.0117 13.7656C42.1159 13.8047 42.2005 13.8633 42.2656 13.9414C42.3438 14.0195 42.4023 14.1107 42.4414 14.2148C42.4935 
        14.306 42.5195 14.4102 42.5195 14.5273Z"
        fill={theme.palette.secondary.main}
      />
    </SvgIcon>
  );
};

CheckmarkIcon.propTypes = {
  fontSize: PropTypes.string,
};

export default CheckmarkIcon;
