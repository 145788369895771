import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../../themes/theme";

const CheckmarkDialogueIcon = ({
  fill = theme.palette.tertiary.main,
  ...props
}) => {
  return (
    <SvgIcon {...props} viewBox="30 10 15 30">
      <path
        d="M36.8945 17.1641L38.0664 18.3555L32.4219 23.9219C32.0964 24.2474 31.7057 24.4102 31.25 24.4102C30.7943 24.4102 30.4036
        24.2474 30.0781 23.9219L26.9336 20.8555L28.1055 19.6641L31.25 22.7305L36.8945 17.1836V17.1641ZM42.4609 20.25V30.2109H32.5C31.1198
        30.2109 29.8307 29.9505 28.6328 29.4297C27.4219 28.9089 26.3607 28.1992 25.4492 27.3008C24.5508 26.3893 23.8411 25.3281 23.3203
        24.1172C22.7995 22.9193 22.5391 21.6302 22.5391 20.25C22.5391 18.8698 22.7995 17.5807 23.3203 16.3828C23.8411 15.1719 24.5508
        14.1172 25.4492 13.2188C26.3607 12.3073 27.4154 11.5911 28.6133 11.0703C29.8242 10.5495 31.1198 10.2891 32.5 10.2891C33.8802
        10.2891 35.1693 10.5495 36.3672 11.0703C37.5781 11.5911 38.6328 12.3073 39.5312 13.2188C40.4427 14.1172 41.1589 15.1654 41.6797
        16.3633C42.2005 17.5742 42.4609 18.8698 42.4609 20.25ZM40.8008 20.25C40.8008 19.1042 40.5859 18.0299 40.1562 17.0273C39.7135
        16.0117 39.1146 15.1328 38.3594 14.3906C37.6172 13.6354 36.7383 13.0365 35.7227 12.5938C34.7201 12.1641 33.6458 11.9492 32.5
        11.9492C31.3542 11.9492 30.2799 12.1641 29.2773 12.5938C28.2617 13.0365 27.3763 13.6354 26.6211 14.3906C25.8789 15.1328
        25.2865 16.0117 24.8438 17.0273C24.4141 18.0299 24.1992 19.1042 24.1992 20.25C24.1992 21.3958 24.4141 22.4701 24.8438
        23.4727C25.2865 24.4883 25.8789 25.3737 26.6211 26.1289C27.3763 26.8711 28.2617 27.4635 29.2773 27.9062C30.2799 28.3359 31.3542 
        28.5508 32.5 28.5508H40.8008V20.25Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

CheckmarkDialogueIcon.propTypes = {
  fill: PropTypes.string,
};

export default CheckmarkDialogueIcon;
