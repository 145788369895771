import React, { useState } from "react";
import theme from "../themes/theme";
import PropTypes from "prop-types";
import { IconButton, useMediaQuery } from "@mui/material";
import InformationIcon from "./icons/InformationIcon";
import StyledTextField from "../styles/StyledTextField";
import SurveyQuestionPopover from "./SurveyQuestionPopover";
import { useController } from "react-hook-form";
import { PatternFormat } from "react-number-format";

const SurveyTextField = React.forwardRef(
  (
    {
      label,
      name,
      autoComplete,
      title,
      description,
      control,
      inputProps,
      maskFormat,
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    const handleOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const eventHandlers = isDesktop
      ? {
          onMouseEnter: handleOpen,
          onMouseLeave: handleClose,
        }
      : {
          onClick: handleOpen,
        };

    const {
      field: { value, onChange, onBlur },
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    return (
      <>
        {maskFormat ? (
          <PatternFormat
            ref={ref}
            value={value}
            onValueChange={(values) => {
              onChange(values.value);
            }}
            onBlur={onBlur}
            customInput={StyledTextField}
            format={maskFormat}
            fullWidth
            label={label}
            name={name}
            autoComplete={autoComplete}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton {...eventHandlers}>
                    <InformationIcon sx={{ fontSize: "1.6rem" }} />
                  </IconButton>
                </>
              ),
              inputProps: inputProps,
              sx: {
                color: error ? theme.palette.secondary.main : "inherit",
                fontSize: 16,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: error ? theme.palette.darkRed : "inherit",
                  },
                },
              },
            }}
            sx={{
              my: 1,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: 16,
                color:
                  error && value
                    ? theme.palette.darkRed
                    : error
                      ? theme.palette.neutralGray
                      : value
                        ? theme.palette.primary.main
                        : theme.palette.neutralGray,
                "&.Mui-focused": {
                  color: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
            }}
            InputLabelProps={{ sx: { fontSize: 16 } }}
          />
        ) : (
          <StyledTextField
            fullWidth
            label={label}
            name={name}
            autoComplete={autoComplete}
            inputRef={ref}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={Boolean(error)}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton {...eventHandlers}>
                    <InformationIcon sx={{ fontSize: "1.6rem" }} />
                  </IconButton>
                </>
              ),
              inputProps: inputProps,
              sx: {
                color: error ? theme.palette.secondary.main : "inherit",
                fontSize: 16,
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: error ? theme.palette.darkRed : "inherit",
                  },
                },
              },
            }}
            sx={{
              my: 1,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: 16,
                color:
                  error && value
                    ? theme.palette.darkRed
                    : error
                      ? theme.palette.neutralGray
                      : value
                        ? theme.palette.primary.main
                        : theme.palette.neutralGray,
                "&.Mui-focused": {
                  color: error
                    ? theme.palette.darkRed
                    : theme.palette.primary.main,
                },
              },
            }}
            InputLabelProps={{ sx: { fontSize: 16 } }}
          />
        )}

        <SurveyQuestionPopover
          anchorEl={anchorEl}
          handleClose={handleClose}
          description={description}
          title={title}
        />
      </>
    );
  },
);
SurveyTextField.displayName = "SurveyTextField";

SurveyTextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  description: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  maskFormat: PropTypes.string,
};

export default SurveyTextField;
