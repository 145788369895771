import React from "react";
import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import StyledTextField from "../styles/StyledTextField";
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import { useController } from "react-hook-form";
import { PatternFormat } from "react-number-format";

const SurveyZipCode = React.forwardRef(
  ({ control, name, errors, spacingBottom, spacingTop }, ref) => {
    const { t } = useTranslation();

    const {
      field: { value, onChange, onBlur },
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    return (
      <Box sx={{ marginTop: spacingTop, marginBottom: spacingBottom }}>
        {errors.zipCode && (
          <Box
            sx={{
              borderTop: `1px solid ${theme.palette.darkRed}`,
              borderBottom: `1px solid ${theme.palette.darkRed}`,
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              backgroundColor: theme.palette.translucentWarmRed,
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                ml: "50px",
                color: theme.palette.darkRed,
                fontFamily: "Public Sans",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {t("zipCodeQuestion.requiredMessage")}
            </Typography>
          </Box>
        )}
        <PatternFormat
          ref={ref}
          value={value}
          onValueChange={(values) => {
            onChange(values.value);
          }}
          onBlur={onBlur}
          customInput={StyledTextField}
          format="#####"
          fullWidth
          label={
            <>
              <span>{t("zipCodeQuestion.placeHolder")}</span>{" "}
              <span
                style={{
                  color: theme.palette.darkRed,
                  fontFamily: "Public Sans",
                  fontStyle: "italic",
                  fontWeight: 400,
                  fontSize: "13px",
                }}
              >
                {t("questionText.required")}
              </span>
            </>
          }
          name={name}
          autoComplete="postal-code"
          error={Boolean(error)}
          InputProps={{
            sx: {
              color: errors.zipCode ? theme.palette.secondary.main : "inherit",
              fontSize: 16,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: errors.zipCode
                    ? theme.palette.darkRed
                    : "inherit",
                },
              },
            },
            inputMode: "numeric",
          }}
          InputLabelProps={{ sx: { fontSize: 16 } }}
          type="tel"
          sx={(theme) => ({
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: errors.zipCode
                  ? theme.palette.darkRed
                  : theme.palette.primary.main,
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: 16,
              color:
                errors.zipCode && value
                  ? theme.palette.darkRed
                  : errors.zipCode
                    ? theme.palette.neutralGray
                    : value
                      ? theme.palette.primary.main
                      : theme.palette.neutralGray,
              "&.Mui-focused > span:first-of-type": {
                color: errors.zipCode
                  ? theme.palette.darkRed
                  : theme.palette.primary.main,
              },
            },
          })}
        />
        <Typography variant="body1" sx={{ my: 1, fontStyle: "italic" }}>
          {t("zipCodeQuestion.helpUsConnect")}
        </Typography>
      </Box>
    );
  },
);
SurveyZipCode.displayName = "SurveyZipCode";

SurveyZipCode.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  spacingBottom: PropTypes.number,
  spacingTop: PropTypes.number,
};

export default SurveyZipCode;
