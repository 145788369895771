import { SvgIcon } from "@mui/material";
import theme from "../../themes/theme";

const InformationIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="20 10 25 25">
      <path
        d="M31.25 15.6797C31.25 15.3411 31.3737 15.0482 31.6211 14.8008C31.8685 14.5534 32.1615 14.4297 32.5
        14.4297C32.8385 14.4297 33.1315 14.5534 33.3789 14.8008C33.6263 15.0482 33.75 15.3411 33.75 15.6797C33.75 
        16.0312 33.6263 16.3307 33.3789 16.5781C33.1315 16.8125 32.8385 16.9297 32.5 16.9297C32.1615 16.9297 31.8685 
        16.8125 31.6211 16.5781C31.3737 16.3307 31.25 16.0312 31.25 15.6797ZM42.4609 20.25V30.2109H32.5C31.1198 
        30.2109 29.8307 29.9505 28.6328 29.4297C27.4219 28.9089 26.3607 28.1992 25.4492 27.3008C24.5508 26.3893 
        23.8411 25.3281 23.3203 24.1172C22.7995 22.9193 22.5391 21.6302 22.5391 20.25C22.5391 18.8698 22.7995 17.5807
        23.3203 16.3828C23.8411 15.1719 24.5508 14.1172 25.4492 13.2188C26.3607 12.3073 27.4154 11.5911 28.6133
        11.0703C29.8242 10.5495 31.1198 10.2891 32.5 10.2891C33.8802 10.2891 35.1693 10.5495 36.3672 11.0703C37.5781 
        11.5911 38.6328 12.3073 39.5312 13.2188C40.4427 14.1172 41.1589 15.1654 41.6797 16.3633C42.2005 17.5742 42.4609 
        18.8698 42.4609 20.25ZM40.8008 20.25C40.8008 19.1042 40.5859 18.0299 40.1562 17.0273C39.7135 16.0117 39.1146 15.1328 
        38.3594 14.3906C37.6172 13.6354 36.7383 13.0365 35.7227 12.5938C34.7201 12.1641 33.6458 11.9492 32.5 11.9492C31.3542 
        11.9492 30.2799 12.1641 29.2773 12.5938C28.2617 13.0365 27.3763 13.6354 26.6211 14.3906C25.8789 15.1328 25.2865 16.0117 
        24.8438 17.0273C24.4141 18.0299 24.1992 19.1042 24.1992 20.25C24.1992 21.3958 24.4141 22.4701 24.8438 23.4727C25.2865 
        24.4883 25.8789 25.3737 26.6211 26.1289C27.3763 26.8711 28.2617 27.4635 29.2773 27.9062C30.2799 28.3359 31.3542 28.5508 
        32.5 28.5508H40.8008V20.25ZM32.5 18.5898H30.8398V20.25H32.5V26.0703H34.1602V20.25C34.1602 19.7943 33.9974 19.4036 33.6719 
        19.0781C33.3594 18.7526 32.9688 18.5898 32.5 18.5898Z"
        fill={theme.palette.tertiary.main}
      />
    </SvgIcon>
  );
};

export default InformationIcon;
