import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(({ theme, isDesktop }) => ({
  "& input": {
    color: theme.palette.secondary.main,
  },
  "& .MuiInputLabel-root": {
    "&.Mui-focused": {
      color: theme.palette.primary.main,
    },
    color: theme.palette.neutralGray,
    transform: isDesktop
      ? "translate(1.2rem, 1rem) scale(1)"
      : "translate(1.4rem, .8rem) scale(1)",
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    transform: "translate(1rem, -.5rem) scale(0.75)",
  },
  "& .MuiOutlinedInput-root": {
    height: isDesktop ? "56px" : "48px",
    "& fieldset": {
      borderColor: theme.palette.secondary.main,
    },
  },
}));

export default StyledTextField;
