import { SvgIcon } from "@mui/material";
import PropTypes from "prop-types";

const TextIcon = ({ fill = "inherit", ...props }) => {
  return (
    <SvgIcon {...props} viewBox="15 5 30 30">
      <path
        d="M32.5 10.25C33.0729 10.25 33.6133 10.3607 34.1211 10.582C34.6289 10.7904 35.0716 11.0833 
        35.4492 11.4609C35.8268 11.8385 36.1198 12.2812 36.3281 12.7891C36.5495 13.2969 36.6602 13.8372 
        36.6602 14.4102V26.0703C36.6602 26.6432 36.5495 27.1836 36.3281 27.6914C36.1198 28.1992 35.8268 
        28.6419 35.4492 29.0195C35.0716 29.3971 34.6289 29.6966 34.1211 29.918C33.6133 30.1263 33.0729 30.2305 
        32.5 30.2305H27.5C26.9271 30.2305 26.3867 30.1263 25.8789 29.918C25.3711 29.6966 24.9284 29.3971 24.5508 
        29.0195C24.1732 28.6419 23.8802 28.1992 23.6719 27.6914C23.4505 27.1836 23.3398 26.6432 23.3398 
        26.0703V14.4102C23.3398 13.8372 23.4505 13.2969 23.6719 12.7891C23.8802 12.2812 24.1732 11.8385 24.5508 
        11.4609C24.9284 11.0833 25.3711 10.7904 25.8789 10.582C26.3867 10.3607 26.9271 10.25 27.5 10.25H32.5ZM35 
        26.0898V25.25H25V26.0898C25 26.7799 25.2409 27.3724 25.7227 27.8672C26.2174 28.349 26.8099 28.5898 27.5 
        28.5898H32.5C33.1901 28.5898 33.776 28.349 34.2578 27.8672C34.7526 27.3724 35 26.7799 35 26.0898ZM35 
        23.5898V23.5703V14.4102C35 13.7201 34.7526 13.1341 34.2578 12.6523C33.776 12.1576 33.1901 11.9102 32.5 
        11.9102H27.5C26.8099 11.9102 26.2174 12.1576 25.7227 12.6523C25.2409 13.1341 25 13.7201 25 14.4102V23.5898H35ZM29.1602 
        26.9102C29.1602 27.1445 29.2383 27.3464 29.3945 27.5156C29.5638 27.6719 29.7656 27.75 30 27.75C30.2344 27.75 
        30.4297 27.6719 30.5859 27.5156C30.7552 27.3464 30.8398 27.1445 30.8398 26.9102C30.8398 26.6758 30.7552 26.4805 
        30.5859 26.3242C30.4297 26.1549 30.2344 26.0703 30 26.0703C29.7656 26.0703 29.5638 26.1549 29.3945 26.3242C29.2383 
        26.4805 29.1602 26.6758 29.1602 26.9102Z"
        fill={fill}
      />
    </SvgIcon>
  );
};

TextIcon.propTypes = {
  fill: PropTypes.string,
};

export default TextIcon;
