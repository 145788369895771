import { SvgIcon } from "@mui/material";
import theme from "../../themes/theme";

const WaitingIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41 41">
      <path
        d="M31 10.5C29.6198 10.5 28.3177 10.7604 27.0938 11.2812C25.8958 11.8021 24.8411 12.5182 23.9297 13.4297C23.0182 
        14.3411 22.3021 15.3958 21.7812 16.5938C21.2604 17.8177 21 19.1198 21 20.5C21 21.8802 21.2604 23.1823 21.7812 24.4062C22.3021 
        25.6042 23.0182 26.6589 23.9297 27.5703C24.8411 28.4818 25.8958 29.1979 27.0938 29.7188C28.3177 30.2396 29.6198 30.5 31 30.5C32.3802 
        30.5 33.6823 30.2396 34.9062 29.7188C36.1042 29.1979 37.1589 28.4818 38.0703 27.5703C38.9818 26.6589 39.6979 25.6042 40.2188 24.4062C40.7396 
        23.1823 41 21.8802 41 20.5C41 19.1198 40.7396 17.8177 40.2188 16.5938C39.6979 15.3958 38.9818 14.3411 38.0703 13.4297C37.1589 12.5182 36.1042 
        11.8021 34.9062 11.2812C33.6823 10.7604 32.3802 10.5 31 10.5ZM31 27.1797C29.151 27.1797 27.5755 26.5286 26.2734 25.2266C24.9714 23.9245 24.3203 
        22.349 24.3203 20.5C24.3203 18.651 24.9714 17.0755 26.2734 15.7734C27.5755 14.4714 29.151 13.8203 31 13.8203C32.849 13.8203 34.4245 14.4714 35.7266 
        15.7734C37.0286 17.0755 37.6797 18.651 37.6797 20.5C37.6797 22.349 37.0286 23.9245 35.7266 25.2266C34.4245 26.5286 32.849 27.1797 31 27.1797ZM30.6094 
        36.0469C30.1406 37.375 29.3203 38.4427 28.1484 39.25C27.0026 40.0833 25.7266 40.5 24.3203 40.5H7.67969C6.6901 40.5 5.7526 40.2917 4.86719 39.875C3.98177 
        39.4844 3.22656 38.9115 2.60156 38.1562C1.97656 37.4271 1.53385 36.6068 1.27344 35.6953C1.01302 34.7839 0.947917 33.8464 1.07812 32.8828C1.23438 31.763 
        1.50781 30.6693 1.89844 29.6016C2.3151 28.5078 2.82292 27.4531 3.42188 26.4375C4.04688 25.3958 4.76302 24.3802 5.57031 23.3906C6.40365 22.401 7.34115 
        21.4375 8.38281 20.5C7.34115 19.5625 6.40365 18.599 5.57031 17.6094C4.76302 16.6198 4.04688 15.6172 3.42188 14.6016C2.82292 13.5599 2.3151 12.4922 1.89844 
        11.3984C1.50781 10.3307 1.23438 9.23698 1.07812 8.11719C0.947917 7.17969 1.01302 6.25521 1.27344 5.34375C1.53385 4.40625 1.97656 3.57292 2.60156 2.84375C3.22656 
        2.08854 3.98177 1.51562 4.86719 1.125C5.7526 0.708333 6.6901 0.5 7.67969 0.5H24.3203C25.7266 0.5 27.0026 0.916667 28.1484 1.75C29.3203 2.55729 30.1406 3.625 30.6094 
        4.95312C30.7656 5.39583 30.7396 5.82552 30.5312 6.24219C30.349 6.65885 30.0365 6.93229 29.5938 7.0625C29.151 7.21875 28.7214 7.20573 28.3047 7.02344C27.9141 6.8151 
        27.6406 6.48958 27.4844 6.04688C27.25 5.39583 26.8333 4.86198 26.2344 4.44531C25.6615 4.02865 25.0234 3.82031 24.3203 3.82031H7.67969C7.1849 3.82031 6.71615 3.92448 
        6.27344 4.13281C5.83073 4.34115 5.45312 4.6276 5.14062 4.99219C4.82812 5.35677 4.60677 5.77344 4.47656 6.24219C4.34635 6.6849 4.30729 7.14062 4.35938 7.60938C4.51562 
        8.65104 4.80208 9.67969 5.21875 10.6953C5.60938 11.6849 6.11719 12.6745 6.74219 13.6641C7.39323 14.6276 8.16146 15.5651 9.04688 16.4766C9.93229 17.4141 10.9349 18.3255 
        12.0547 19.2109C12.263 19.3672 12.4193 19.5625 12.5234 19.7969C12.6276 20.0052 12.6797 20.2396 12.6797 20.5C12.6797 20.7604 12.6276 21.0078 12.5234 21.2422C12.4193 21.4766 
        12.263 21.6589 12.0547 21.7891C10.9349 22.6745 9.93229 23.5859 9.04688 24.5234C8.1875 25.4349 7.43229 26.3854 6.78125 27.375C6.13021 28.3385 5.60938 29.3151 5.21875 
        30.3047C4.80208 31.3203 4.51562 32.349 4.35938 33.3906C4.30729 33.8594 4.34635 34.3151 4.47656 34.7578C4.60677 35.2005 4.82812 35.6172 5.14062 36.0078C5.45312 36.3724 5.83073 
        36.6589 6.27344 36.8672C6.71615 37.0755 7.1849 37.1797 7.67969 37.1797H24.3203C25.0234 37.1797 25.6615 36.9714 26.2344 36.5547C26.8333 36.138 27.25 35.6042 27.4844 34.9531C27.6406 
        34.5104 27.9271 34.1979 28.3438 34.0156C28.7604 33.8073 29.1771 33.7812 29.5938 33.9375C30.0365 34.0677 30.349 34.3411 30.5312 34.7578C30.7396 35.1745 30.7656 35.6042 30.6094 
        36.0469ZM33.8516 21.0078C34.1641 21.3203 34.3203 21.7109 34.3203 22.1797C34.3203 22.6224 34.1641 23.013 33.8516 23.3516C33.6953 23.5078 33.513 23.638 33.3047 23.7422C33.0964 23.8203 
        32.888 23.8594 32.6797 23.8594C32.4714 23.8594 32.263 23.8203 32.0547 23.7422C31.8464 23.638 31.6641 23.5078 31.5078 23.3516L29.8281 21.6719C29.6719 21.5156 29.5417 21.3464 29.4375 
        21.1641C29.3594 20.9557 29.3203 20.7344 29.3203 20.5V17.1797C29.3203 16.7109 29.4766 16.3203 29.7891 16.0078C30.1276 15.6693 30.5312 15.5 31 15.5C31.4688 15.5 31.8594 15.6693 32.1719 
        16.0078C32.5104 16.3203 32.6797 16.7109 32.6797 17.1797V19.8359L33.8516 21.0078ZM14.9844 23.7422L14.0859 24.4062C13.1224 25.1875 12.0677 26.1901 10.9219 27.4141C9.80208 28.612 8.94271 
        29.9792 8.34375 31.5156C8.23958 31.776 8.20052 32.0495 8.22656 32.3359C8.2526 32.5964 8.34375 32.8438 8.5 33.0781C8.65625 33.3125 8.85156 33.4948 9.08594 33.625C9.34635 33.7292 9.60677 
        33.7812 9.86719 33.7812H22.1328C22.4193 33.7812 22.6797 33.7292 22.9141 33.625C23.1745 33.4948 23.3828 33.3125 23.5391 33.0781C23.6693 32.8438 23.7474 32.5964 23.7734 32.3359C23.8255 
        32.0495 23.7995 31.776 23.6953 31.5156C23.0703 29.9792 22.1979 28.612 21.0781 27.4141C19.9583 26.1901 18.9036 25.1875 17.9141 24.4062L17.0547 23.7422C16.7422 23.4818 16.3906 23.3516 16 
        23.3516C15.6094 23.3516 15.2708 23.4818 14.9844 23.7422ZM19.2812 30.5H12.7188C13.2135 29.849 13.7474 29.237 14.3203 28.6641C14.9193 28.0911 15.4792 27.5964 
        16 27.1797C16.5208 27.5964 17.0677 28.0911 17.6406 28.6641C18.2396 29.237 18.7865 29.849 19.2812 30.5Z"
        fill={theme.palette.tertiary.main}
      />
    </SvgIcon>
  );
};

export default WaitingIcon;
