import { useEffect } from "react";
import PropTypes from "prop-types";
import WaitingBanner from "../components/WaitingBanner";

const GenesysPlatformService = ({ config }) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const handleMessengerReady = () => {
      window.Genesys("command", "Messenger.open");
    };

    const handleLauncherReady = () => {
      window.Genesys("command", "Database.set", {
        messaging: {
          customAttributes: {
            upset_amount: config.data.upset_amount || "NULL",
            chat_session_id: config.data.chat_session_id || "NULL",
            thoughts_of_suicide: config.data.thoughts_of_suicide || "NULL",
            gender_unlisted: config.data.gender_unlisted || "NULL",
            concern_unlisted: config.data.concern_unlisted || "NULL",
            is_trevor: config.data.is_trevor || "NULL",
            name: config.data.name || "NULL",
            submit_timestamp: config.data.submit_timestamp || "NULL",
            age: config.data.age || "NULL",
            postal_code: config.data.postal_code || "NULL",
            gender: config.data.gender || "NULL",
            concern: Array.isArray(config.data.concern)
              ? config.data.concern.join("; ")
              : "NULL",
            contact_details: config.data.contact_details || "NULL",
            transgender: config.data.transgender || "NULL",
            client_ip: config.data.client_ip || "NULL",
            isp: config.data.isp || "NULL",
            locale: config.data.locale || "NULL",
          },
        },
      });
    };

    const handleConnectionClosed = () => {
      window.location.reload();
    };

    window.Genesys("subscribe", "Messenger.ready", handleMessengerReady);
    window.Genesys("subscribe", "Launcher.ready", handleLauncherReady);
    window.Genesys(
      "subscribe",
      "MessagingService.connectionClosed",
      handleConnectionClosed,
    );

    return () => {
      window.Genesys("unsubscribe", "Messenger.ready", handleMessengerReady);
      window.Genesys("unsubscribe", "Launcher.ready", handleLauncherReady);
      window.Genesys(
        "unsubscribe",
        "MessagingService.connectionClosed",
        handleConnectionClosed,
      );
    };
  }, [config]);

  return <WaitingBanner />;
};

GenesysPlatformService.propTypes = {
  config: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }).isRequired,
};

export default GenesysPlatformService;
