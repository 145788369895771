import { SvgIcon } from "@mui/material";

const XCircle = (props) => {
  return (
    <SvgIcon
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.2793 7.19043L13.2207 14.2607H11.9902L7.04883 7.19043H8.2793ZM20.1348 10.7256C20.1348 11.3896 20.0697 12.0472 19.9395 12.6982C19.8092 13.3363 19.6204 13.9548 19.373 14.5537C19.1257 15.1527 18.8197 15.7256 18.4551 16.2725C18.0905 16.8193 17.6738 17.3271 17.2051 17.7959C16.7363 18.2777 16.2285 18.7008 15.6816 19.0654C15.1348 19.43 14.5618 19.736 13.9629 19.9834C13.3639 20.2308 12.7454 20.4131 12.1074 20.5303C11.4564 20.6605 10.7988 20.7256 10.1348 20.7256C9.4707 20.7256 8.81315 20.6605 8.16211 20.5303C7.52409 20.4001 6.9056 20.2113 6.30664 19.9639C5.70768 19.7165 5.13477 19.4105 4.58789 19.0459C4.04102 18.6813 3.5332 18.2646 3.06445 17.7959C2.5957 17.3271 2.17904 16.8193 1.81445 16.2725C1.44987 15.7256 1.14388 15.1527 0.896484 14.5537C0.649089 13.9548 0.460286 13.3363 0.330078 12.6982C0.19987 12.0472 0.134766 11.3896 0.134766 10.7256C0.134766 10.0615 0.19987 9.40397 0.330078 8.75293C0.460286 8.11491 0.649089 7.49642 0.896484 6.89746C1.14388 6.2985 1.44987 5.72559 1.81445 5.17871C2.17904 4.63184 2.5957 4.12402 3.06445 3.65527C3.5332 3.18652 4.04102 2.76986 4.58789 2.40527C5.13477 2.04069 5.70768 1.7347 6.30664 1.4873C6.9056 1.23991 7.52409 1.05111 8.16211 0.920898C8.81315 0.79069 9.4707 0.725586 10.1348 0.725586C10.7988 0.725586 11.4564 0.79069 12.1074 0.920898C12.7454 1.05111 13.3639 1.23991 13.9629 1.4873C14.5618 1.7347 15.1348 2.04069 15.6816 2.40527C16.2285 2.76986 16.7363 3.18652 17.2051 3.65527C17.6738 4.12402 18.0905 4.63184 18.4551 5.17871C18.8197 5.72559 19.1257 6.2985 19.373 6.89746C19.6204 7.49642 19.8092 8.11491 19.9395 8.75293C20.0697 9.40397 20.1348 10.0615 20.1348 10.7256ZM15.0176 15.2178L11.3262 9.94434C11.3262 9.94434 11.502 9.75553 11.8535 9.37793C12.2051 8.9873 12.5892 8.56413 13.0059 8.1084C13.4355 7.63965 13.8262 7.20996 14.1777 6.81934C14.5293 6.42871 14.7051 6.2334 14.7051 6.2334H13.416L10.7793 9.14355L8.72852 6.2334H5.21289L8.76758 11.3311L5.21289 15.2178H6.50195L9.33398 12.1318L11.502 15.2178H15.0176Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default XCircle;
