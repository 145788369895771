import React from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import theme from "../themes/theme";
import { useTranslation } from "react-i18next";
import StyledTextField from "../styles/StyledTextField";
import { PatternFormat } from "react-number-format";
import { useController } from "react-hook-form";

const SurveyPhoneNumberQuestion = React.forwardRef(
  ({ control, name, errors }, ref) => {
    const { t } = useTranslation();

    const {
      field: { value, onChange, onBlur },
      fieldState: { error },
    } = useController({
      name,
      control,
    });

    return (
      <>
        <Typography
          variant="h2"
          sx={{
            color: "secondary.main",
          }}
        >
          {t("phoneNumberQuestion.title")}
        </Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontStyle: "italic",
            mb: 1,
            color: theme.palette.secondary.main,
          }}
        >
          {t("questionText.optional")}
        </Typography>
        {errors.phoneNumber && (
          <Box
            sx={{
              borderTop: `1px solid ${theme.palette.darkRed}`,
              borderBottom: `1px solid ${theme.palette.darkRed}`,
              height: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              backgroundColor: theme.palette.translucentWarmRed,
              mb: "16px",
            }}
          >
            <Typography
              sx={{
                ml: "50px",
                color: theme.palette.darkRed,
                fontFamily: "Public Sans",
                fontWeight: 600,
                fontSize: "15px",
              }}
            >
              {t("phoneNumberQuestion.mustBe10DigitsMessage")}
              <br />
              {t("phoneNumberQuestion.lengthOf10Message")}
            </Typography>
          </Box>
        )}
        <PatternFormat
          ref={ref}
          value={value}
          onValueChange={(values) => {
            onChange(values.value);
          }}
          onBlur={onBlur}
          customInput={StyledTextField}
          format="(###) ###-####"
          mask="_"
          fullWidth
          label={t("phoneNumberQuestion.placeHolder")}
          placeholder="(___) ___-____"
          name={name}
          autoComplete="tel"
          error={Boolean(error)}
          InputProps={{
            sx: {
              color: errors.phoneNumber
                ? theme.palette.secondary.main
                : "inherit",
              fontSize: 16,
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: errors.phoneNumber
                    ? theme.palette.darkRed
                    : "inherit",
                },
              },
            },
            inputMode: "numeric",
          }}
          InputLabelProps={{ sx: { fontSize: 16 } }}
          sx={(theme) => ({
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: errors.phoneNumber
                  ? theme.palette.darkRed
                  : theme.palette.primary.main,
              },
            },
            "& .MuiInputLabel-root": {
              fontSize: 16,
              color:
                errors.phoneNumber && value
                  ? theme.palette.darkRed
                  : errors.phoneNumber
                    ? theme.palette.neutralGray
                    : value
                      ? theme.palette.primary.main
                      : theme.palette.neutralGray,
              "&.Mui-focused": {
                color: errors.phoneNumber
                  ? theme.palette.darkRed
                  : theme.palette.primary.main,
              },
            },
          })}
          type="tel"
        />
      </>
    );
  },
);
SurveyPhoneNumberQuestion.displayName = "SurveyPhoneNumberQuestion";

SurveyPhoneNumberQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default SurveyPhoneNumberQuestion;
