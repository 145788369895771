import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "../locales/en/translation.json";
import esTranslation from "../locales/es/translation.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["querystring", "navigator", "htmlTag", "localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"],
      checkWhitelist: true,
      lookupQuerystring: "lang",
    },
    whitelist: ["en", "es"],
  });

export default i18n;
