import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
  Box,
  Typography,
  Container,
  useMediaQuery,
  useTheme,
  Link,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import WaitingIcon from "./icons/WaitingIcon";
import CallWaitIcon from "./icons/CallWaitIcon";
import { Autoplay } from "swiper/modules";

const slides = [
  {
    message: "waitingBanner.message1",
    icon: <WaitingIcon key="waiting-icon" sx={{ fontSize: "40px", mr: 2 }} />,
  },
  {
    message: "waitingBanner.message2",
    icon: (
      <CallWaitIcon key="call-wait-icon" sx={{ fontSize: "40px", mr: 2 }} />
    ),
  },
];

const WaitingBanner = () => {
  const { t } = useTranslation();
  const [dots, setDots] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const title = isConnected
    ? t("waitingBanner.connected")
    : t("waitingBanner.inLine");

  useEffect(() => {
    let dotsInterval;

    if (!isConnected) {
      dotsInterval = setInterval(() => {
        setDots((prev) => (prev.length < 3 ? prev + "." : ""));
      }, 750);
    }

    const handleMessageReceived = ({ data }) => {
      const { direction, originatingEntity } = data.messages[0];

      if (direction === "Outbound" && originatingEntity === "Human") {
        setIsConnected(true);
        clearInterval(dotsInterval);
        setDots("");
      }
    };

    window.Genesys(
      "subscribe",
      "MessagingService.messagesReceived",
      handleMessageReceived,
    );

    return () => {
      window.Genesys(
        "unsubscribe",
        "MessagingService.messagesReceived",
        handleMessageReceived,
      );
      clearInterval(dotsInterval);
    };
  }, [isConnected]);

  return (
    <Container disableGutters sx={{ pt: isDesktop ? 0 : 5 }}>
      <Typography
        variant="h4"
        component="h1"
        sx={{ textAlign: "center", my: 8 }}
      >
        {title}
        <Box
          sx={{
            display: "inline-block",
            width: "1.5em",
            textAlign: "left",
          }}
        >
          {dots}
        </Box>
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Swiper
          spaceBetween={20}
          autoplay={{
            delay: 15000,
            disableOnInteraction: false,
          }}
          loop={true}
          speed={1500}
          modules={[Autoplay]}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#F7F7FF",
                  padding: "16px",
                  borderRadius: "8px",
                  mb: 12,
                }}
              >
                {slide.icon}
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    {t("waitingBanner.header")}
                  </Typography>
                  <Typography variant="h6">
                    {index === 1 ? (
                      <Trans
                        i18nKey={slide.message}
                        components={[
                          <Link href="tel:988" key="988-link">
                            988
                          </Link>,
                        ]}
                      />
                    ) : (
                      t(slide.message)
                    )}
                  </Typography>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Container>
  );
};

export default WaitingBanner;
