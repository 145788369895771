import { SvgIcon } from "@mui/material";

const ChatDialogueIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="20 5 25 25">
      <path
        d="M39.1602 10.25C40.0846 10.25 40.8724 10.5755 41.5234 11.2266C42.1745 11.8776 42.5 12.6654 42.5 13.5898V23.5703C42.5 
        24.4948 42.1745 25.2826 41.5234 25.9336C40.8724 26.5846 40.0846 26.9102 39.1602 26.9102H36.7578C36.7578 26.9102 36.5625 
        27.0729 36.1719 27.3984C35.7812 27.724 35.3516 28.0885 34.8828 28.4922C34.4271 28.8828 34.0039 29.2409 33.6133 29.5664C33.2357 
        29.8919 33.0469 30.0547 33.0469 30.0547C32.9688 30.1198 32.8841 30.1654 32.793 30.1914C32.7018 30.2305 32.6042 30.25 32.5 
        30.25C32.3958 30.25 32.2982 30.2305 32.207 30.1914C32.1159 30.1654 32.0312 30.1198 31.9531 30.0547L28.2422 26.9102H25.8398C24.9154 
        26.9102 24.1276 26.5846 23.4766 25.9336C22.8255 25.2826 22.5 24.5013 22.5 23.5898V13.5898C22.5 12.6654 22.8255 11.8776 
        23.4766 11.2266C24.1276 10.5755 24.9154 10.25 25.8398 10.25H39.1602ZM40.8398 23.5898H40.8594V13.5898C40.8594 13.1341 
        40.6966 12.7435 40.3711 12.418C40.0456 12.0924 39.6484 11.9297 39.1797 11.9297H25.8594C25.3906 11.9297 24.9935 12.0924 
        24.668 12.418C24.3424 12.7305 24.1797 13.1211 24.1797 13.5898V23.5703C24.1797 24.026 24.3424 24.4167 24.668 24.7422C24.9935 
        25.0677 25.3906 25.2305 25.8594 25.2305H28.2617C28.4701 25.2305 28.6654 25.2695 28.8477 25.3477C29.0299 25.4128 29.1927 
        25.5104 29.3359 25.6406L32.5195 28.3164C32.5195 28.3164 32.6823 28.1797 33.0078 27.9062C33.3333 27.6328 33.6914 27.3268 
        34.082 26.9883C34.4857 26.6497 34.8568 26.3438 35.1953 26.0703C35.5208 25.7839 35.6836 25.6406 35.6836 25.6406C35.8268 
        25.5234 35.9896 25.4323 36.1719 25.3672C36.3542 25.2891 36.5495 25.25 36.7578 25.25H39.1797C39.6354 25.25 40.026 25.0872 
        40.3516 24.7617C40.6771 24.4362 40.8398 24.0456 40.8398 23.5898ZM27.5 15.25C27.5 15.4844 27.5781 15.6862 27.7344 15.8555C27.9036 
        16.0117 28.1055 16.0898 28.3398 16.0898H32.5C32.7344 16.0898 32.9297 16.0117 33.0859 15.8555C33.2552 15.6862 33.3398 15.4844 
        33.3398 15.25C33.3398 15.0156 33.2552 14.8203 33.0859 14.6641C32.9297 14.4948 32.7344 14.4102 32.5 14.4102H28.3398C28.1055 
        14.4102 27.9036 14.4948 27.7344 14.6641C27.5781 14.8203 27.5 15.0156 27.5 15.25ZM37.5 18.5898C37.5 18.3555 37.4154 18.1602 
        37.2461 18.0039C37.0898 17.8346 36.8945 17.75 36.6602 17.75H28.3203C28.0859 17.75 27.8841 17.8346 27.7148 18.0039C27.5586 
        18.1602 27.4805 18.3555 27.4805 18.5898C27.4805 18.8242 27.5586 19.026 27.7148 19.1953C27.8841 19.3516 28.0859 19.4297 
        28.3203 19.4297H36.6602C36.8945 19.4297 37.0898 19.3516 37.2461 19.1953C37.4154 19.026 37.5 18.8242 37.5 18.5898ZM37.5 
        21.9102C37.5 21.6758 37.4154 21.4805 37.2461 21.3242C37.0898 21.1549 36.8945 21.0703 36.6602 21.0703H28.3203C28.0859 
        21.0703 27.8841 21.1549 27.7148 21.3242C27.5586 21.4805 27.4805 21.6758 27.4805 21.9102C27.4805 22.1445 27.5586 22.3464 
        27.7148 22.5156C27.8841 22.6719 28.0859 22.75 28.3203 22.75H36.6602C36.8945 22.75 37.0898 22.6719 37.2461 22.5156C37.4154 
        22.3464 37.5 22.1445 37.5 21.9102Z"
        fill={"white"}
      />
    </SvgIcon>
  );
};

export default ChatDialogueIcon;
